import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from './reportWebVitals';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import{
    Home,
    Header,
    Footer,
    About,
    Gallery,
    Paintball,
    Places,
    OneNightTwoDay,
    CampingInRishikesh,
    RaftingInRishikesh,
    RiverResortInRishikesh,
    ThreeNightsFourDays,
    TrekkingInRishikesh,
    TwoNightThreeDay,
    ResortInRishikesh,
    ZiplineInRishikesh,
    BungeeJumping,
    Contact,
    FlyingFoxinRishikesh,
} from "./components";
ReactDOM.render(
    <Router>
    <Header />
    <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/gallery' element={<Gallery />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='/paintball' element={<Paintball />}/>
        <Route path='/places' element={<Places />}/>
        <Route path='/trekking-in-rishikesh' element={<TrekkingInRishikesh />}/>
        <Route path='/two-night-three-day' element={<TwoNightThreeDay />}/>
        <Route path='/one-night-two-day' element={<OneNightTwoDay />}/>
        <Route path='/camping-in-rishikesh' element={<CampingInRishikesh />}/>
        <Route path='/rafting-in-rishikesh' element={<RaftingInRishikesh />}/>
        <Route path='/river-resort-in-rishikesh' element={<RiverResortInRishikesh />}/>
        <Route path='/three-nights-four-days' element={<ThreeNightsFourDays />}/>
        <Route path='/resort-in-rishikesh' element={<ResortInRishikesh />}/>
        <Route path='/bungee-jumping-in-rishikesh' element={<BungeeJumping />}/>
        <Route path='/zipline-in-rishikesh' element={<ZiplineInRishikesh />}/>
        <Route path='/flying-fox-in-rishikesh' element={<FlyingFoxinRishikesh />}/>

    </Routes>
    <Footer />
    </Router>,
    document.getElementById("root")
);

reportWebVitals();
serviceWorker.unregister();
