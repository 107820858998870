import React from 'react';
import { Helmet } from 'react-helmet';

function ZiplineInRishikesh() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - Zipline Package in Rishikesh</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/zip-line.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Zipline In Rishikesh</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Zipline In Rishikesh</li>
            </ul>
          </div>
        </div>
        <div className="blog-single-area pt-60 pb-60">
          <div className="container">
            <div className="row">
              <h1 align="center" className="mb-30" style={{ fontSize: 36 }}>
                Zipline Package In Rishikesh
              </h1>
              <div className="col-sm-6">
                <div className="blog-thumb-img">
                  <img src="assets/img/blog/blog-17.jpg" alt="thumb" />
                </div>
              </div>
              <div className="col-sm-6">
                <p>
                  A pulley suspended on a cable, typically made of stainless steel
                  and mounted on a slope, is referred to as a zip-line, zip-wire,
                  flying fox, or death slide. By holding on to or being attached to
                  the freely moving pulley, it is intended to allow cargo or a
                  person being propelled by gravity to travel from the top to the
                  bottom of the inclined cable. According to some descriptions, it
                  is essentially a Tyrolean traverse that uses gravity to speed up
                  movement. Despite the fact that modern usage tends to favour these
                  meanings, it is not only used in adventure sports, leisure
                  activities, or tourism.
                </p>
                <p>
                  {" "}
                  The only activity that crosses the Ganga River and is 200 feet
                  above the white water rapids is the zipline in Rishikesh. A person
                  participating in this activity is attached to a cable that was
                  built to allow someone moving along at the speed of gravity to
                  move from the top to the bottom of the inclined cable.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-wrapper">
                  <div className="blog-single-content">
                    <div className="blog-info">
                      <div className="blog-details">
                        <h2 className="blog-details-title mb-20">
                          Zip Line Activity In Rishikesh
                        </h2>
                        <div className="row">
                          <p>
                            If you want to start your morning off right with an
                            exhilarating zip line ride, go for it. You can add
                            ziplining to your amazing outdoor adventure package,
                            along with our other adventure activities like rafting
                            in Rishikesh, kayaking, camping in Rishikesh, and yoga.
                            The Ganga River's lone zip line in Rishikesh offers a
                            refreshing change of pace from rafting and hiking. On
                            the Ganga River, there is nothing like our river rafting
                            and zip line package. You'll get a breathtaking
                            bird's-eye view of the campsite as well as breathtaking
                            views of the Himalayan foothills and the mighty Ganga
                            when you climb the steps up to the zip line starting
                            deck. That's only the start. During your visit with us,
                            zip lining is a requirement without fail. Whether you're
                            a child or an adult, you simply cannot miss it.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-20">
                            <img src="assets/img/blog/blog-15.jpg" alt="" />
                          </div>
                          <div className="col-md-6 mb-20">
                            <img src="assets/img/blog/blog-16.jpg" alt="" />
                          </div>
                        </div>
                        <div className="row" style={{ border: '1px solid black' }}>
                          <div className="col-sm-12">


                            <h2 className="blog-details-title mb-20">
                              How to get to a Zipline Tour in Rishikesh
                            </h2>
                            <p align="justify">
                              Delhi, the Indian capital, is 245 kilometres away from
                              Rishikesh. It is 55 kilometres away from Dehradun. The
                              small village of Shivpuri, which was previously
                              well-known for its rafting excursions, is home to the
                              thrilling zipline tour. Shivpuri is 16 kilometres from
                              Rishikesh. Arriving in Rishikesh:
                            </p>
                            <div className="row" style={{borderTop:'1px solid black'}}>

                              <div className="col-sm-4" style={{ borderRight: '1px solid black' }}>

                                <h4>By Air: </h4><p align="justify"> Joly Grant Airport is the closest
                                  airport to Rishikesh, located 20 kilometres away. You
                                  can take a taxi to Shivpuri from there.</p>
                              </div>
                              <div className="col-sm-4" style={{ borderRight: '1px solid black' }}>

                                <h4>By Train:</h4><p align="justify"> Rishikesh had a railway station. You
                                  must then take a taxi to Shivpuri from there.</p>
                              </div>
                              <div className="col-sm-4">

                                <h4>By cab or scooty rental:</h4><p align="justify"> if you are not
                                  driving your own vehicle. Then, you may go to Tapovan
                                  or the Bus Stand to take a taxi or rent a scooter.</p>
                              </div>
                            </div>
                          </div>

                        </div>
                        <br />
                        <h2 className="blog-details-title mb-20">
                          Rishikesh Zipline Information
                        </h2>
                        <div className="row">
                          <p>
                            It is one of the most thrilling adventure activities in
                            Rishikesh, and hundreds of people take part in it each
                            year. Your face will be rubbed by winds that are moving
                            at above 140 km/h. The safety briefing and demo zip are
                            included in the 45–60 minute trip duration.
                          </p>
                          <p></p>
                          <ul>
                            <li>Zipline 70 metres tall.</li>
                            <li>Distance on zipline: 750 metres.</li>
                            <li>
                              Age requirement: 10 years (Must be at least 4 feet 7
                              inches tall)
                            </li>
                            <li>Maximum weight: 100 kg</li>
                            <li>Zipline Place: Shivpuri (16 kms from Rishikesh)</li>
                          </ul>
                          <p />
                        </div>
                        <hr />
                        <div className="faq-area pt-60">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6 mx-auto">
                                <div className="site-heading text-center">
                                  <span className="site-title-tagline">FAQ's</span>
                                  <h2 className="site-title">General Question</h2>
                                  <p>
                                    confused? Let us respond to all of your queries.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 align-self-center">
                                <div className="faq-img">
                                  <img src="assets/img/faq/faq2.jpg" alt="" />
                                </div>
                              </div>
                              <div className="col-lg-6 align-self-center">
                                <div className="accordion" id="accordionExample">
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        <i className="far fa-question" /> Is
                                        ziplining in Rishikesh a safe activity?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        We always put your safety first. Because of
                                        this, our zip line was constructed to the
                                        strictest international safety standard.
                                        Because our zip line is approved for safety,
                                        you can unwind and enjoy the journey. To
                                        ensure everyone's safety, All riders must
                                        weigh between 60 and 240 pounds.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        <i className="far fa-question" />
                                        How long does it take to finish a zipline
                                        tour?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        30 to 45 min. This includes a little stroll
                                        between the two zip lines and a 20-minute
                                        safety lecture.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        <i className="far fa-question" /> What makes
                                        the zipline trip in Rishikesh unique?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        It is both the longest zipline in
                                        Uttarakhand and the only trip that crosses
                                        the Ganga. Less time is needed to complete
                                        the activity than river rafting. Although
                                        more pricey than rafting, it is still
                                        worthwhile. &amp; Operated in accordance
                                        with international safety standards, 70
                                        metres above the Ganga.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*?php include "footer.php"
    ?*/}
    </>

  )
}

export default ZiplineInRishikesh