import React from 'react';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <>
    <Helmet>
      <title>Him River Resort - Contact</title>
    </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/contact.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Contact Us</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Contact Us</li>
            </ul>
          </div>
        </div>
        <div className="contact-area py-120">
          <div className="container">
            <div className="contact-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-map">
                  <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.2805028572147!2d78.37619801511676!3d30.114785981856855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909174308e07467%3A0x9594639044991cf1!2sHim%20River%20Resort!5e0!3m2!1sen!2sin!4v1663753054139!5m2!1sen!2sin"
  width={600}
  height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

                  </div>
                </div>
                <div className="col-md-6 align-self-center">
                  <div className="contact-form">
                    <div className="contact-form-header">
                      <h2>Get In Touch</h2>
                      
                    </div>
                    <form
                      method="post"
                      action="/rosewod/assets/php/contact.php"
                      id="contact-form"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Your Name"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Your Email"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Mobile Number"
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          cols={30}
                          rows={5}
                          className="form-control"
                          placeholder="Write Your Message"
                          defaultValue={""}
                        />
                      </div>
                      <button type="submit" className="contact-btn">
                        {" "}
                        <i className="far fa-paper-plane" /> Send Message
                      </button>
                      <div className="col-md-12 mt-3">
                        <div className="form-messege text-success" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-content">
              <div className="row">
                <div className="col-sm-12">
                  <div className="contact-info">
                    <i className="fal fa-map-marker-alt" />
                    <h5>Office Address</h5>
                    <p>Neelkanth Road, Phool Chatti, Laxman Jhula, Rishikesh, Uttarakhand, 249302</p>
                  </div>
                </div>
              </div>
              <div className="row">
                
                <div className="col-md-6 col-lg-4">
                  <div className="contact-info">
                    <i className="fal fa-phone" />
                    <h5>Call Us</h5>
                    <a href="tel:90123 20202">90123 20202</a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="contact-info">
                    <i className="fal fa-envelope" />
                    <h5>Email Us</h5>
                    <p>
                      <a href="mailto:himriverlife@gmail.com" className="__cf_email__" data-cfemail="ec85828a83ac89948d819c8089c28f8381">
                      himriverlife@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="contact-info">
                    <i className="fal fa-clock" />
                    <h5>Office Open</h5>
                    <p>12 P.M. - 10 A.M.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      
    </>

  )
}

export default Contact