import React from 'react';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - About</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/about-bg.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">About Us</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">About Us</li>
            </ul>
          </div>
        </div>
        <div className="container-fluid">
          <h1 className='text-center pt-30 pb-30'>About us</h1>
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6 p-0 m-0">
              <img src="assets/img/about/about-image-01.jpg" style={{ width: '100%', height: '450px', objectFit: 'cover' }} alt="" />
            </div>
            <div className="col-sm-6 m-0">
              <p>
                Stay at one of these top resorts in Rishikesh, India for an
                exceptional experience, like the Him River Resort. Visit Rishikesh and
                stay there with family or friends to experience the greatest
                hospitality.
              </p>
              <p>
                In addition to staying on the resort grounds, visitors to Him River
                Resorts &amp; Spa are free to explore Rishikesh. Although each
                property at the Him River Resort is in a different location, they are
                all connected by a network of unparalleled services. Recently, the
                majority of our room types underwent renovations that included
                retro-inspired decor.
              </p>
              <p>
                Additionally, visitors may take part in the Ganga Aarti live &amp;
                Spas pebble beach or action-packed excursions like white-river
                rafting, trekking, bungee jumping, paintball, and going to a nearby
                villages. Directly on the banks of the
                revered Ganges River in Rishikesh lies the yoga and wellness resort
                known as Him river resort on The Ganges.
              </p>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-center"
          >
            <div className="col-sm-6 m-0">
              <p align="justify">
                Him River Resort offers premium cottages with electricity and connected bathrooms. You can easily spot amazing birds if you travel in the forest. The magnificent Patna Falls is just 1.1 km away from the cottages. Him River Resort is the ideal destination for those who like to be close to nature. So you are warmly welcomed at Him River Resort.

                Your body, mind, and soul can be refreshed because to the serene surroundings of Him River Resort. By combining nature, meditation, and wholesome meals, Him River Resort provides the kind of energy that would calm your mind, take your problems away, and assist you in recovering from the side effects of busy lifestyle. The resort provides luxury accommodations with a variety of luxurious features.
                <br /><br />
                Looking out over the holy city of Rishikesh, encircled by the Ganga
                River and huge mountains, in all its natural and historical glory, is
                one of the most remarkable experiences you may have in the Ramjhula.
                Ram Jhula connects Sivananda Nagar at Muni Ki Reti in the Tehri
                Garhwal district to Swargashram in the Pauri Garhwal district to the
                east. It is situated on top of the holy Ganga River. About 8+
                kilometres from Rishikesh, the state of Uttarakhand's, is
                where you'll find this bridge.
              </p>
            </div>
            <div className="col-sm-6 p-0 m-0">
              <img src="assets/img/about/about-image-02.jpg" style={{ width: '100%', height: '450px', objectFit: 'cover' }} alt="" />
            </div>
          </div>
        </div>
      </main>

    </>

  )
}

export default About