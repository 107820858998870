import React from 'react'
import { Helmet } from 'react-helmet'
function CampingInRishikesh() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - Camping in Rishikesh</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/camping.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Camping In RIshikesh</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Camping In RIshikesh</li>
            </ul>
          </div>
        </div>
        <div className="blog-single-area pt-60">
          <div className="container">
            <div className="row">
              <h1 align="center" className='pb-30' style={{ fontSize: 36 }}>
                Camping In Rishikesh
              </h1>
              <div className="col-sm-6">
                <div className="blog-thumb-img">
                  <img src="assets/img/blog/camping-2.jpg" alt="thumb" />
                </div>
              </div>
              <div className="col-sm-6">
                <p>
                  Rishikesh is a very beautiful and adventurous city. If you want to
                  go camping in Rishikesh, then at the picturesque confluence of the
                  Ganges and snowy rivers in the foothills of the Himalayas above
                  Rishikesh, Him River Resort Rishikesh is a good option. Located at
                  a distance of about 6 kms from Laxman Jhula (near Rapid Golf
                  Course), Phoolchatti Ashram is close to an old pilgrimage path in
                  the sources of Ganga. Him River Resort Rishikesh has unique mud
                  cottages that are designed in a basic yet comfortable way for a
                  couple or family with 24 hour electricity, connected bath and
                  running tap water. Mountains and gorgeous rivers encircle the
                  resort on all sides. Each of our cottages is thoughtfully planned
                  to ensure that guests have a wonderful time. This campsite is an
                  ideal place for Rishikesh camping to try Rishikesh rafting,
                  camping, etc.The camp offers activities such as night party,
                  forest walks, Hiking to the nearby natural River, yoga and
                  meditation are other must-do activities at Him River Resort
                  Rishikesh.{" "}
                </p>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  If you are planning your holiday in Rishikesh, then you must have
                  an adventurous rafting and wonderful camping experience in
                  Rishikesh so you can choose Him River Resort Rishikesh under clear
                  skies full of twinkling stars. Whether it's rafting on the mighty
                  Ganges, or just breathing in the fresh air, or camping on the
                  banks of the Ganges, Rishikesh is the place to be. In addition to
                  exploring and worshiping the mystical Ganges River, you can enjoy
                  various adventure tours such as rafting, camping packages offered
                  by Him River Resort Rishikesh.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-wrapper">
                  <div className="blog-single-content">
                    <div className="blog-info">
                      <div className="blog-details">
                        <h2 className="blog-details-title mb-30 mt-30">
                          What you Find in Him River Resort Camping
                        </h2>
                        <p className="mb-10">
                          A very unusual resort is located in the Himalayas. You may
                          travel 200 metres from the resort to the River Ganga Ghat,
                          where you'll be enchanted by its meditative ambiance. You
                          may stay in a luxury river view hotel and take in the
                          river. Anyone seeking a tranquil vacation in the
                          wilderness might consider staying at this resort. The Him
                          River Resort's main feature is its location right beside a
                          river and amid mountains, which gives the impression that
                          we are lounging in Mother Nature's lap. For lovers of
                          nature, this resort is ideal. The Him River Resort is a
                          good option if you enjoy outdoor activities and being in
                          nature. The housekeeping was excellent, always ready to
                          serve, and the room was very nice and clean. And don't
                          worry about the food Him River Resort always tries to give
                          you good and better food and takes care of your health.
                          And every morning you can also do yoga by enjoying nature
                          in the garden or by the river. If you are looking for a
                          good riverside and mountain camping resort, then you can
                          give Him River Resort a chance.
                        </p>
                        <blockquote className="blockqoute">
                          Step outside your home. Leave the city and head for a
                          camp. Don't bring your problems. Your problems only
                          pertain to your city, not your camp. Feel the excitement
                          within you!
                        </blockquote>
                        <div className="row">
                          <h3 align="center" className='mb-30 mt-30' style={{ fontSize: 36 }}>
                            WHY PEOPLE CHOOSE US?
                          </h3>
                          <p>
                            At Him River Resort, we work hard to ensure that all our
                            visitors feel comfortable. We also provide a good
                            service to our visitor along with a good camping. We
                            hope that we and our visitor to Him River Resort have a
                            good time together. In terms of personalised service and
                            hospitality, we try to surpass what the best resorts in
                            Rishikesh have to offer.
                          </p>
                          <h3 style={{ fontSize: 25 }}>
                            We provide the greatest service to our clients.
                          </h3>
                          <p>
                            We deliver to you the highest calibre services for which
                            we are known. When you come here, you may feel
                            liberated. In addition, Rishikesh's natural environs are
                            available for your enjoyment. We assist you in improving
                            your journey. We assure that you will enjoy yourself
                            immensely here.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-20">
                            <img src="assets/img/blog/blog-4.jpg" alt="" />
                          </div>
                          <div className="col-md-6 mb-20">
                            <img src="assets/img/blog/blog-5.jpg" alt="" />
                          </div>
                        </div>
                        <h3 style={{ fontSize: 25 }}>Best rate ensured</h3>
                        <p className="mb-20">
                          We are known for offering you the best service at
                          reasonable prices. We also make a lot of effort to upgrade
                          the amenities we provide you in Rishikesh.
                        </p>
                        <hr />
                        <div className="row">
                          <h2>Facilities in Him River Resort</h2>
                          <ul className="a">
                            <li> Free Wi-Fi</li>
                            <li> Room service</li>
                            <li> Backup power</li>
                            <li>A dining area</li>
                            <li> Safety and security for you</li>
                            <li> Yoga</li>
                            <li> A meditation room</li>
                            <li> An activity centre </li>
                            <li> Bike rental and car rental are available</li>
                          </ul>
                        </div><hr />
                        <div className="faq-area pb-60">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6 mx-auto">
                                <div className="site-heading text-center">
                                  <span className="site-title-tagline">FAQ's</span>
                                  <h2 className="site-title">General Question</h2>
                                  <p>
                                    confused? Let us respond to all of your queries.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 align-self-center">
                                <div className="faq-img">
                                  <img src="assets/img/faq/faq.jpg" alt="" />
                                </div>
                              </div>
                              <div className="col-lg-6 align-self-center">
                                <div className="accordion" id="accordionExample">
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        <i className="far fa-question" /> Which time
                                        of year is ideal for camping?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        Everything relies on your goals. Although
                                        the summer will always be the most popular
                                        time to go camping since you can swim and
                                        soak up the sun all day, more and more
                                        people are starting to appreciate the
                                        outdoors in the spring and fall (and even
                                        the winter).
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        <i className="far fa-question" /> What is
                                        service facilities?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        The restrooms, showers, washbasins, space
                                        for doing laundry, and eating area are all
                                        located in one or more service buildings
                                        that are always open at campgrounds. All of
                                        the visitors share these spaces, so be
                                        careful to leave them as spotless and
                                        organised as you would want to find them.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        <i className="far fa-question" /> Is Camping
                                        safe or not?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        One of the most secure and safe outdoor
                                        activities you can do with your friends & families in
                                        Rishikesh in Him River Resort without
                                        sacrificing enjoyment is camping.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*?php include "footer.php"
	?*/}
    </>

  )
}

export default CampingInRishikesh