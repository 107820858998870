import React from 'react';
import { Helmet } from 'react-helmet';

function FlyingFoxinRishikesh() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - Flying Fox in Rishikesh</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/flying-fox.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Flying Fox in Rishikesh</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Flying Fox in Rishikesh</li>
            </ul>
          </div>
        </div>
      

        <div className="blog-single-area pt-60 pb-60">
          <div className="container">
            <div className="row">
              <h1 align="center" className="mb-30" style={{ fontSize: 36 }}>
                Flying Fox Package in Rishikesh
              </h1>
              <div className="col-sm-6">
                <div className="blog-thumb-img">
                  <img src="assets\img\gallery\flying-fox.jpg" alt="thumb" />
                </div>
              </div>
              <div className="col-sm-6">
                <p>
                  Flying Fox activity allows you to fly like a bird after you are
                  harnessed to a rope with all the safety gears in place. Then you
                  can use the cable to cross to the opposite side, and the pace of
                  the line can be raised to jolt your nerves with adrenaline.
                  <br />
                  Thrill-seekers, take advantage of Asia's longest Flying Fox
                  experience to soar at 160 kilometer per hour! Together with your
                  best companions, soar across the forest at breathtaking heights!
                  <br />
                  Flying fox rides are available in Rishikesh, where you may glide
                  across stunning Himalayan scenery while standing a few metres
                  above a rippling river.
                  <br />
                  In the wondrous and peaceful land above the Ganges Brook in
                  Rishikesh, take part in a wholly modern Flying Fox experience.
                  Flying fox is unquestionably the most amazing yet spine-chilling
                  sport that we are presenting to our sports fans. Take a break with
                  your close friends and travel with the cool air to encounter a
                  disparate in Rishikesh.
                  <br />
                </p>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  Flying Fox India offers Tandem rides for 3 persons (including
                  you), however if you only want to ride alone, you can do so for an
                  additional fee. Walk up to the cafeteria after Flying Fox to calm
                  your tense nerves and gather the memorial photos of your
                  adventure.
                  <br />
                  <br />
                  Some of the most breathtaking and intriguing places to visit in
                  Rishikesh (Uttarakhand) are the vast Himalayan mountain ranges.
                  They can also be the most inaccessible and challenging locations
                  to reach, and the only practical method to visit them is on foot.
                  This is because they are frequently not served by highways.
                  Trekking may be a goal in itself for some individuals, a way to
                  experience the breathtaking views for others, and frequently the
                  locals of the mountains, with their cultures, customs, and faiths,
                  offer just as much fascination as the landscape.
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="blog-single-wrapper">
                <div className="blog-single-content">
                  <div className="blog-info">
                    <div className="blog-details">
                      <div className="row">

                        <div className="col-lg-6">
                          <img
                            src="assets\img\gallery\flying-fox-blog.jpg"
                            alt=""
                          />
                        </div>

                        <div className="col-lg-6">
                          <h3 className="blog-details-title mb-20">
                            Steps for Flying Fox :-
                          </h3>
                          <br />
                          <ul>
                            <li>
                              👉 Equipment linked to the body is demonstrated by a
                              skilled trainer.
                            </li>
                            <br />
                            <li>
                              👉 Equipment installation is managed and controlled by
                              experts.
                            </li>
                            <br />
                            <li>
                              👉 Feel free to enjoy the airflow, thrill, and speed.
                            </li>
                            <br />
                            <li>
                              👉 Return to the cafeteria and enjoy viewing your
                              jump-related photos and videos.
                            </li>
                            <br />
                            <li>
                              👉 Please remember to pick up your Dare to Jump
                              certificate.
                            </li>
                          </ul>
                        </div>

                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6">
                          <h3 className="blog-details-title mb-20">
                            Goal of Flying Fox Activity :-
                          </h3>
                          <ul>
                            <li>
                              🤝 To combat people who are afraid of heights,
                              gravity.
                            </li>
                            <br />
                            <li>
                              🤝 Experience breathtaking sights when flying through
                              the area while taking in the scenery.
                            </li>
                          </ul>
                          <br />
                          <br />
                          <h3 className="blog-details-title mb-20">
                            Criteria for Flying Fox :-
                          </h3>
                          <ul>
                            <li>🎗️ Minimum - 12 yrs</li>
                            <br />
                            <li>🎗️ Weight - Minimum – 20 kg Maximum – 130 kg</li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <img
                            src="assets\img\gallery\flying-fox-blog-01.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                      <h3 style={{ fontSize: 25 }}>Conclusion :-</h3>
                      <br />
                      <p>
                        You can try the exercise alone or with a group. The
                        participant is secured and prepared for safety gear when the
                        installation and inspection are complete. Once the person is
                        prepared, they are later released to enjoy a flight of up to
                        one kilometer. We confirm the fact that this activity is
                        quite exciting and one of the must-try activities at Him
                        River Resort, especially with the surrounding scenery of
                        Rishikesh.
                      </p>
                      <hr />
                      <div className="faq-area pt-60">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-6 mx-auto">
                              <div className="site-heading text-center">
                                <span className="site-title-tagline">FAQ's</span>
                                <h2 className="site-title">General Question</h2>
                                <p>
                                  confused? Let us respond to all of your queries.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 align-self-center">
                              <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingOne">
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      <i className="far fa-question" /> When is the
                                      ideal time to book a trip to Rishikesh for
                                      adventure?
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      The ideal time to book a trip for an adventure
                                      to Rishikesh is during the winter, from
                                      October to March. The weather is still cold at
                                      this time, but Rishikesh is still
                                      breathtakingly beautiful.
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      <i className="far fa-question" /> What is best
                                      place for Treeking in uttrakhand?
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      Uttarakhand is a adventurous city and in
                                      rishikesh there are many trekking spots. Him
                                      River Resort provide best places for trekking.
                                      Your adventure become memorable if you choose
                                      Him River Resort.
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      <i className="far fa-question" /> Is Flying
                                      Fox safe or not?
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      In general, Flying Fox is very rewarding and a
                                      once-in-a-lifetime experience. While Flying
                                      Fox is not dangerous, it is still important to
                                      always consider your health and safety. And
                                      there is nothing to worry about Flying Fox.
                                      Him River Resort takes full care of your
                                      safety.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*?php include "footer.php"
    ?*/}
    </>

  )
}

export default FlyingFoxinRishikesh