import React from 'react';
import { Helmet } from 'react-helmet';

function Places() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - Places</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/places.jpg)" }}
        >
          <div className="container">
            <h2 className="title" style={{ marginTop: "-50px", color: "white" }}>
              About Rishikesh (Uttarakhand)
            </h2>
            <br />
            <div className="row">
              <div className="col-sm-2" />
              <div className="col-sm-8" style={{ color: "orange" }}>
                <p style={{ fontWeight: "bold" }}>
                  In the foothills of the Himalayas next to the Ganges River, in the
                  northern Indian state of Uttarakhand, sits the city of Rishikesh.
                  The city is recognised as a centre for learning yoga and
                  meditation, and the river is regarded as holy. Upstream from
                  Rishikesh town is Swarg Ashram, a traffic-free, alcohol-free, and
                  vegetarian community lined with temples and ashrams (centres for
                  spiritual education).
                </p>
              </div>
              <div className="col-sm-2" />
            </div>
          </div>
        </div>
        <div className="container pt-60 pb-60">
          <div className="row">
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/rishikesh.jpg" alt="" />
                <h4
                  style={{ textAlign: "center", marginTop: 20, fontWeight: "bold" }}
                >
                  LAKSHMAN JHULA
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  Lakshman Jhula is the name of one of the most recognisable hanging
                  bridges over the sacred Ganga. The jhula links two large Rishikesh
                  settlements. It connects the communities of Jonk in Pauri Garhwal
                  and Tapovan in Tehri Garhwal.The bridge, which currently looms
                  above the Ganga, is one of the primary tourist attractions in the
                  Rishikesh tourism corridor.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/teramanjil.jpg" alt="" />
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase"
                  }}
                >
                  Tera manzil temple
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  The temple is renowned for its impressive size and exquisite
                  design. It is a 13-story building with numerous little temples
                  devoted to numerous Hindu gods and goddesses on each floor. In
                  contrast to other temples, the Tera Manzil temple is not devoted
                  to a particular deity. A panoramic view of the sunset over
                  Rishikesh's mountains may be seen from the temple's top floor.
                </p>
              </div>
            </div>
          </div>
          <div className="row pt-30">
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/shivapuri.jpg" alt="" />
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase"
                  }}
                >
                  Shivpuri
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  A popular spot for river rafting, Shivpuri is a little village
                  located 16 kilometres from Rishikesh and is tucked away in the
                  picturesque Shivalik hills. This tiny but beautiful hamlet has
                  become well recognised for its adventure tourism because of its
                  river rafting on the holy Ganga and several great campsites. The
                  Ganga River, where water adventure activities like rafting and
                  kayaking are practised, is the primary attraction in this village.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/neelkanth-inner-blog.jpg" alt="" />
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase"
                  }}
                >
                  Neelkanth mandir
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  Lord Shiva's aspect of Nilkanth is honoured in a Hindu temple
                  called Neelkanth Mahadev. The temple lies 29 kilometres from
                  Rishikesh in the Indian state of Uttarakhand's Pauri Garhwal
                  region. The temple design has been significantly influenced by the
                  Dravidian style of temple architecture. The temple, which honours
                  Lord Shiva, is a popular Hindu site for pilgrimage. It is
                  encircled by dense forests and is near to the Nar-Narayan mountain
                  ranges.
                </p>
              </div>
            </div>
          </div>
          <div className="row pt-30">
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/patna-inner-blog.jpg" alt="" />
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase"
                  }}
                >
                  Patna Water Fall
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  The small settlement where this waterfall is located bears the
                  name of Patna. The limestone caves near to the waterfall are
                  another reason for its fame. On the road leading to Neelkanth
                  temple, the hike to the waterfall begins 5 km from Lakshman Jhula.
                  From the road, it is a stunning 1.5 kilometre hike into Rajaji
                  Forest. The hike to the waterfall is strenuous and steep. It is
                  not advisable to see the waterfalls during the summer because they
                  would be virtually dry. Visitors must exercise caution and clarify
                  the path with locals because there are no signboards directing
                  them to the waterfall.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/bungee-inner-blog.jpg" alt="" />
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase"
                  }}
                >
                  Bungee Jumping
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  Lakshman Jhula is the name of one of the most recognisable hanging
                  bridges over the sacred Ganga. The jhula links two large Rishikesh
                  settlements. It connects the communities of Jonk in Pauri Garhwal
                  and Tapovan in Tehri Garhwal.The bridge, which currently looms
                  above the Ganga, is one of the primary tourist attractions in the
                  Rishikesh tourism corridor.
                </p>
                <p style={{ textAlign: "justify", padding: "5px 10px" }}>The age limit to participate in this activity is twelve. The person must weigh between 35 and 120 kg.</p>
              </div>
            </div>
          </div>
          <div className="row pt-30">
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/jhilmil-gufa-inner-bolg.jpg" alt="" />
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase"
                  }}
                >
                  Jhilmil Gufa
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  Near the Nilkanth Mahadev Temple, a short 4-hour walk called
                  Jhilmil Gufa is available. Within the woodland lies a small cave
                  called Jhilmil Brahmakoot, and Vishnukoot valleys surround it, and
                  it is located at the confluence of the Pankaja and Madhumati
                  rivers.This cave is dedicated to the supreme devotee of Lord Shiva, Baba Gorakhnath. This cave is quite ancient and full of old secrets. There is a description in the Puranas that Baba Gorakhnath had done austerities to Lord Shiva for thousands of years by setting up Hawan in this cave. At this place, Lord Shiva and Baba Gorakhnath had discussed yoga together for a long time.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="div-1"
                style={{
                  border: "1px solid gray",
                  borderRadius: 5,
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,.1)"
                }}
              >
                <img src="./assets/img/blog/garudchattifall.jpg" alt="" />
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase"
                  }}
                >
                  Garud Chatti Water fall
                </h4>
                <p style={{ textAlign: "justify", padding: 10 }}>
                  Garud Chatti waterfall is located near Rishikesh on Neelkanth
                  road, 9 km from the Rishikesh Railway Station and 4 km from
                  Lakshman Jhula. One of the popular destinations in Rishikesh,
                  especially for foreign visitors, is the Garud Chatti waterfall.
                  The waterfall is modest in size but lovely. The monsoon season,
                  when the water pours at seven distinct levels, is when this
                  waterfall is at its most spectacular. A few hours can be spent
                  there enjoying yourself. On the Neelkanth Mandir Road, about 3 km
                  from Lakshman Jhula, the hike route to Garud Chatti Waterfall
                  begins. Starting from a Garuda temple, the walk begins.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*?php include "footer.php"
      ?*/}
    </>

  )
}

export default Places