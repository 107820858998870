import React from 'react';
import { Helmet } from 'react-helmet';

function OneNightTwoDay() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - 1 Night / 2 Days Package</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/ONTD.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">1 Night / 2 Days Package</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">1 Night / 2 Days Package</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center pt-60">
            <h1 style={{ fontSize: 36 }}>1 Night / 2 Days Package In Rishikesh</h1>
          </div>
          <div className="row pb-30" style={{ alignItems: "center", justifyContent: "center" }}>
            <div className="col-md-8" style={{ textAlign: "center" }}>
              <p style={{ color: "orange" }}>
                <strong> We Provide All Kinds of Adventure Activities Like River Rafting,
                  Bungee Jumping, Camping, Flying Fox, Trekking, Paint Ball in
                  Rishikesh.</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <div className="row">
              <div className="col-sm-6 pe-4">
                <h5>1 Night / 2 Days Adventure Package</h5>
                <p align="justify">
                  Get Greatest vaccation of all time on a typical overnight stay package at a miraculous
                  resort&nbsp;as a reward for yourself. <br />
                  Get an adventure package that fits in your pocket and excitement as well. Wanna enjoy your weekend with peace, calm, soothness?. 
                  <br /> If you book it quickly, it will be simpler for you to go to
                  these locations. Destinations accessible in two days are
                  frequently found close to important cities. In several locations
                  in India. Look at
                  these locations where you may travel and enjoy
                  yourself to the utmost. <br />
                  Him river resort offers many packages that you even can't think of him river resort provides various packages such as rafting, trekking, bungee jumping, zipline, flying fox, camping.
                  <br />
                  The middle of the year is when most people from India or across
                  the world want to travel to Rishikesh. Call us to reserve your
                  package and have a lovely one night and two days in Rishikesh.
                  With your family or friends, you must select us for
                  the Him River Resort Premium Package for 1 Night and 2 Days if you intend
                  to explore, take part in water sports, and engage in other
                  adventurous activities. With one of our well-liked holiday
                  packages. <br />
                  This 1-night, 2-day package from Rishikesh is made to match your
                  budget while yet allowing you to spend the weekend unwinding in
                  the great outdoors. Vacation Deal Stay in any of the residence's
                  rooms at the Him River Resort. <br />
                  Beginning the day of check-in and through the day of
                  departure, access to the resort is allowed during regular business
                  hours (except when the resort is open on the day of departure).
                  To enjoy free parking on qualifying packages, you must show a valid e-ticket and a picture ID. Our goal is to
                  provide you with well-planned packages for your two-day vacation. <br />

                </p>
              </div>
              <div className="col-sm-6">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-bs-ride="true"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={0}
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    />
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={1}
                      aria-label="Slide 2"
                    />
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={2}
                      aria-label="Slide 3"
                    />
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="assets/img/rafting/rafting-image-02.jpg"
                        style={{ width: "100%", height: 300 }}
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="assets/img/rafting/rafting-image-05.jpg"
                        style={{ width: "100%", height: 300 }}
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="assets/img/rafting/rafting-image-06.jpg"
                        style={{ width: "100%", height: 300 }}
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>

                <div className="row mt-3" style={{border:'1px solid black'}}>
                  <div className="col-sm-6" style={{borderRight: '1px solid black'}}>
                    <span className="">
                      <b>
                        <img src="assets/img/1N2D/hot-coffee.png" alt="" /> Breakfast
                        Info
                      </b>
                    </span>
                    <br />
                    <span>Continental</span>
                    <br />
                    <span>Buffet</span>
                  </div>
                  <div className="col-sm-6">
                    <span className="">
                      <b>
                        <img src="assets/img/1N2D/bedroom.png" alt="" /> Rooms With:
                      </b>
                    </span>
                    <br />
                    <span>Balcony View</span>
                    <br />
                    <span>River View</span>
                  </div>
                  <hr />
                  <div className="col-sm-12 mt-2 mb-3">
                    <span className='fw-bold'>
                      <img src="assets/img/1N2D/parking.png" alt="" /> Private Parking
                      Availiable
                    </span>
                  </div>
                  <hr />
                  <div className="col-sm-6" style={{borderRight: '1px solid black'}}>
                    <span className="">
                      <b>
                        <img src="assets/img/1N2D/exercise.png" alt="" /> Activities
                      </b>
                    </span>
                    <br />
                    <span>Volleyball Ground</span>
                    <br />
                    <span>Children Playground</span>
                  </div>
                  <div className="col-sm-6">
                    <span className="">
                      <b>
                        <img src="assets/img/1N2D/protection.png" alt="" /> Your Stay
                        Include
                      </b>
                    </span>
                    <br />
                    <span>Balcony</span>
                    <br />
                    <span>Dining Area</span>
                  </div>
                  <hr />
                  <div className="col-sm-12 mt-2">
                    <span>
                      <b>
                        <img src="assets/img/1N2D/trending-topic.png" alt="" /> Most
                        Popular facilities
                      </b>
                    </span>
                    <br />
                    <span>
                      <img src="assets/img/1N2D/wifi.png" alt="" /> Free Wi-fi,{" "}
                      <img src="assets/img/1N2D/cafe.png" alt="" /> Restaurant,{" "}
                      <img src="assets/img/1N2D/dinner.png" alt="" /> Family Rooms,<img src="assets/img/1N2D/no-smoking.png" alt="" />{" "}
                      Non-Smoking Rooms
                    </span>
                  </div>
                  <div className="mb-10 d-block mt-10 d-grid gap-3">
                    <a href="/contact" className="btn btn-primary">
                      Book Rooms Now
                    </a>
                  </div>
                </div>

                {/* <div
                  className="d-inline-block mt-20"
                  style={{
                    borderRadius: 5,
                    padding: "10px 30px",
                    color: "white",
                    background: "blue",
                    width: "47.6%",
                    marginRight: 20
                  }}
                >
                  <span className="">
                    <b>
                      <img src="assets/img/1N2D/hot-coffee.png" alt="" /> Breakfast
                      Info
                    </b>
                  </span>
                  <br />
                  <span>Continental</span>
                  <br />
                  <span>Buffet</span>
                </div>
                <div
                  className="d-inline-block"
                  style={{
                    borderRadius: 5,
                    padding: "10px 30px",
                    color: "white",
                    background: "limegreen",
                    width: "47.6%"
                  }}
                >
                  <span className="">
                    <b>
                      <img src="assets/img/1N2D/bedroom.png" alt="" /> Rooms With:
                    </b>
                  </span>
                  <br />
                  <span>Balcony View</span>
                  <br />
                  <span>River View</span>
                </div>
                <div
                  className="mb-10 d-block mt-10"
                  style={{
                    borderRadius: 5,
                    padding: 5,
                    color: "white",
                    background: "teal",
                    width: "100%"
                  }}
                >
                  <span>
                    <img src="assets/img/1N2D/parking.png" alt="" /> Private Parking
                    Availiable
                  </span>
                </div>
                <div
                  className="d-inline-block"
                  style={{
                    borderRadius: 5,
                    padding: "10px 30px",
                    color: "white",
                    background: "#FCA702",
                    width: "47.6%",
                    marginRight: 20
                  }}
                >
                  <span className="">
                    <b>
                      <img src="assets/img/1N2D/exercise.png" alt="" /> Activities
                    </b>
                  </span>
                  <br />
                  <span>Volleyball Ground</span>
                  <br />
                  <span>Children Playground</span>
                </div>
                <div
                  className="d-inline-block"
                  style={{
                    borderRadius: 5,
                    padding: "10px 30px",
                    color: "white",
                    background: "#20C997",
                    width: "47.6%"
                  }}
                >
                  <span className="">
                    <b>
                      <img src="assets/img/1N2D/protection.png" alt="" /> Your Stay
                      Include
                    </b>
                  </span>
                  <br />
                  <span>Balcony</span>
                  <br />
                  <span>Dining Area</span>
                </div>
                <div
                  className="mb-10 d-block mt-10"
                  style={{
                    borderRadius: 5,
                    padding: 5,
                    color: "white",
                    background: "#D63384",
                    width: "100%"
                  }}
                >
                  <span>
                    <b>
                      <img src="assets/img/1N2D/trending-topic.png" alt="" /> Most
                      Popular facilities
                    </b>
                  </span>
                  <br />
                  <span>
                    <img src="assets/img/1N2D/wifi.png" alt="" /> Free Wi-fi,{" "}
                    <img src="assets/img/1N2D/cafe.png" alt="" /> Restaurant,{" "}
                    <img src="assets/img/1N2D/dinner.png" alt="" /> Family Rooms,
                    <br /> <img src="assets/img/1N2D/no-smoking.png" alt="" />{" "}
                    Non-Smoking Rooms
                  </span>
                </div>
                <div className="mb-10 d-block mt-10 d-grid gap-3">
                  <a href="/contact" className="btn btn-primary">
                    Book Rooms Now
                  </a>
                </div> */}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="row pt-20" style={{ border: "1px solid grey", boxShadow: 'rgba(0, 0, 0, 0.55) 5.95px 5.95px 10.6px' }}>
                  <div className="col-sm-6">
                    <h4>Inclusions</h4>
                    <p></p>
                    <ul>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;
                        Accommodation for one night and two days in a cottage with
                        a bathroom attached is included.
                      </li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;3-meal buffet</li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;AM PM cookies and tea.</li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;
                        Everyday evening bonfire with vegetarian snacks. (Bonfire
                        is subject to weather conditions.)
                      </li>
                    </ul>
                    <p />
                  </div>
                  <div className="col-sm-6">
                    <h4>Services &amp; Amenities at the Resorts</h4>
                    <ul className="N1D2">
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Cottages with adjoining restrooms.</li>
                      <li>
                        <span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Pillows, blankets, linen, and beds are given at camps.
                      </li>
                      <li>
                        <span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Charging stations for laptops, cameras, and mobile devices
                        in the camps.
                      </li>
                      <li>
                        <span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Located on the edge of Rajaji National Park in a serene
                        valley and surrounded by nature. 20 stairs separate the camp
                        from the River Heul.
                      </li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;River Ganga is 500 metres uphill.</li>

                    </ul>
                  </div>
                </div>
                <h4 className="mt-20 pt-20" style={{ borderTop: "1px solid grey" }}>
                  About Our Resort
                </h4>
                <p>
                  Him River Resort Neelkhant Road Rishikesh .
                  <br /> 249302 Rishikesh, India – Show map
                  <br />
                  Him River Resort offers a children's playground.
                </p>
                <h4>Note</h4>
                <p
                  style={{
                    fontSize: "larger",
                    border: "1px solid grey",
                    padding: 15
                  }}
                >
                  <span style={{ color: "#FCA702" }}>River rafting</span>,
                  <span style={{ color: "blue" }}> bungee jumping</span>,
                  <span style={{ color: "#D63384" }}> camping</span>,
                  <span style={{ color: "green" }}> flying fox</span>,
                  <span style={{ color: "indigo" }}> hiking</span>, and
                  <span style={{ color: "skyblue" }}> paintballing</span> are all
                  popular activities here.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-1" />
        </div>
      </main>

    </>
  )
}

export default OneNightTwoDay