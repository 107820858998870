import React from 'react';
import { Helmet } from 'react-helmet';

function ThreeNightsFourDays() {
  return (
    <>
      <Helmet>
        <title>
          Him River Resort - 3 Nights / 4 Days Package
        </title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/TNFD.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">3 Night / 4 Days Package</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li className="active">3 Night / 4 Days Package</li>
            </ul>
          </div>
        </div>
        <div className="blog-single-area pt-60 pb-60">
          <div className="container">
            <div className="row">
              <h1 align="center" style={{ fontSize: "40px" }}>
                3 Night / 4 Days Package In Rishikesh
              </h1>
              <br />
              <p style={{ textAlign: "center" }}>
                <b style={{ color: "black" }}>" Best Room Price Guranteed "</b>
                <br />
              </p>
              <br />
              <br />
              <div className="col-sm-6">
                <div className="blog-thumb-img">
                  <img src="assets\img\gallery\main-image.jpg" alt="thumb" />
                </div>
              </div>
              <div className="col-sm-6">
                <h5 style={{ textAlign: "center" }}>
                  {" "}
                  <b> 3 Night / 4 Days Adventure Package </b>
                </h5>

                <p>
                  This 3 nights 4 days package is ideal for newlyweds, friends,
                  family, business trips looking to enjoy the vibrant beauty of the
                  Ganga sea. Its surreal beauty, colorful underwater world, and
                  great resorts view&nbsp;attracts travellers who appreciate the
                  peace and quiet that prevails here. Surrounded by jaw-dropping
                  views, lush green woods, and important and significant
                  attractions, Uttarakhand is surely one of the most appreciated and
                  cherished holiday destinations in the country, and Him River
                  Resort is always there to help you enjoy your vacations. Now as
                  you check-in the resort, you will be welcomed by the heartwarming
                  staff of the resort.
                </p>
              </div>
            </div>
            <hr />
            <div className="row mt-3" style={{ border: '1px solid black' }}>
              <div className="col-sm-6" style={{ borderRight: '1px solid black' }}>
                <span className="">
                  <b>
                    <img src="assets/img/1N2D/hot-coffee.png" alt="" /> Breakfast
                    Info
                  </b>
                </span>
                <br />
                <span>Continental</span>
                <br />
                <span>Buffet</span>
              </div>
              <div className="col-sm-6">
                <span className="">
                  <b>
                    <img src="assets/img/1N2D/bedroom.png" alt="" /> Rooms With:
                  </b>
                </span>
                <br />
                <span>Balcony View</span>
                <br />
                <span>River View</span>
              </div>
              <hr />
              <div className="col-sm-12 mt-2 mb-3">
                <span className='fw-bold'>
                  <img src="assets/img/1N2D/parking.png" alt="" /> Private Parking
                  Availiable
                </span>
              </div>
              <hr />
              <div className="col-sm-6" style={{ borderRight: '1px solid black' }}>
                <span className="">
                  <b>
                    <img src="assets/img/1N2D/exercise.png" alt="" /> Activities
                  </b>
                </span>
                <br />
                <span>Volleyball Ground</span>
                <br />
                <span>Children Playground</span>
              </div>
              <div className="col-sm-6">
                <span className="">
                  <b>
                    <img src="assets/img/1N2D/protection.png" alt="" /> Your Stay
                    Include
                  </b>
                </span>
                <br />
                <span>Balcony</span>
                <br />
                <span>Dining Area</span>
              </div>
              <hr />
              <div className="col-sm-12 mt-2">
                <span>
                  <b>
                    <img src="assets/img/1N2D/trending-topic.png" alt="" /> Most
                    Popular facilities
                  </b>
                </span>
                <br />
                <span>
                  <img src="assets/img/1N2D/wifi.png" alt="" /> Free Wi-fi,{" "}
                  <img src="assets/img/1N2D/cafe.png" alt="" /> Restaurant,{" "}
                  <img src="assets/img/1N2D/dinner.png" alt="" /> Family Rooms,<img src="assets/img/1N2D/no-smoking.png" alt="" />{" "}
                  Non-Smoking Rooms
                </span>
              </div>
              <div className="mb-10 d-block mt-10 d-grid gap-3">
                <a href="/contact" className="btn btn-primary">
                  Book Rooms Now
                </a>
              </div>
            </div>
            <hr />
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="row pt-20" style={{ border: "1px solid grey", boxShadow: 'rgba(0, 0, 0, 0.55) 5.95px 5.95px 10.6px' }}>
                  <div className="col-sm-6">
                    <h4>Inclusions</h4>
                    <p></p>
                    <ul>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;
                        Accommodation for one night and two days in a cottage with
                        a bathroom attached is included.
                      </li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;3-meal buffet</li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;AM PM cookies and tea.</li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;
                        Everyday evening bonfire with vegetarian snacks. (Bonfire
                        is subject to weather conditions.)
                      </li>
                    </ul>
                    <p />
                  </div>
                  <div className="col-sm-6">
                    <h4>Services &amp; Amenities at the Resorts</h4>
                    <ul className="N1D2">
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Cottages with adjoining restrooms.</li>
                      <li>
                        <span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Pillows, blankets, linen, and beds are given at camps.
                      </li>
                      <li>
                        <span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Charging stations for laptops, cameras, and mobile devices
                        in the camps.
                      </li>
                      <li>
                        <span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;Located on the edge of Rajaji National Park in a serene
                        valley and surrounded by nature. 20 stairs separate the camp
                        from the River Heul.
                      </li>
                      <li><span className="far fa-check-circle" style={{ color: '#FCA702' }} />&nbsp;River Ganga is 500 metres uphill.</li>

                    </ul>
                  </div>
                </div>
                <h4 className="mt-20 pt-20" style={{ borderTop: "1px solid grey" }}>
                  About Our Resort
                </h4>
                <p>
                  Him River Resort Neelkhant Road Rishikesh .
                  <br /> 249302 Rishikesh, India – Show map
                  <br />
                  Him River Resort offers a children's playground.
                </p>
                <h4>Note</h4>
                <p
                  style={{
                    fontSize: "larger",
                    border: "1px solid grey",
                    padding: 15
                  }}
                >
                  <span style={{ color: "#FCA702" }}>River rafting</span>,
                  <span style={{ color: "blue" }}> bungee jumping</span>,
                  <span style={{ color: "#D63384" }}> camping</span>,
                  <span style={{ color: "green" }}> flying fox</span>,
                  <span style={{ color: "indigo" }}> hiking</span>, and
                  <span style={{ color: "skyblue" }}> paintballing</span> are all
                  popular activities here.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*?php include "footer.php"
    ?*/}
    </>

  )
}

export default ThreeNightsFourDays