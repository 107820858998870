import React from 'react';
import { Helmet } from 'react-helmet';

function RaftingInRishikesh() {
    return (
        <>
            <Helmet>
                <title>Him River Resort - Rafting In Rishikesh Package</title>
            </Helmet>
            <main className="main">
                <div
                    className="site-breadcrumb"
                    style={{ background: "url(assets/img/bg/rafting.jpg)" }}
                >
                    <div className="container">
                        <h2 className="breadcrumb-title">Rafting Package In Rishikesh</h2>
                        <ul className="breadcrumb-menu">
                            <li>
                                <a href="index.php">Home</a>
                            </li>
                            <li className="active">Rafting Package In Rishikesh </li>
                        </ul>
                    </div>
                </div>
                <div className="blog-single-area pt-60 pb-60">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <h1
                                align="center"
                                style={{ fontSize: 36 }}
                            >
                                Rafting Package In Rishikesh
                            </h1>
                        </div>
                        <div className="row pt-40">
                            <div className="col-sm-6">
                                <div className="blog-thumb-img">
                                    <img src="assets/img/rafting/rafting-image-02.jpg" style={{ borderRadius: '15px' }} alt="thumb" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <p>
                                    Why Ganges River Rafting in Rishikesh One of the most thrilling
                                    experiences and unforgettable moments of your life, Ganges River
                                    Rafting in Rishikesh is a surreal adventure that will leave you
                                    trembling with excitement, fear, and pleasure. The section from
                                    Brahamapuri to Rishikesh may be ideal for beginners who want to
                                    try river rafting in Rishikesh for the first time. Rishikesh
                                    provides the ideal opportunity for white water rafting, regardless
                                    of the adventurer's skill level. In Rishikesh, Expeditions India
                                    provides a variety of trekking, camping, and rafting options. One
                                    of the most popular rafting rivers in India is the Rishikesh
                                    River. The best white water rafting location in India is probably
                                    Rishikesh, the country's rafting capital. One of Rishikesh's most
                                    well-known activities is river rafting, which has grown in
                                    popularity alongside sea travel and neem beach. Regardless of the
                                    adventurer's choice of route, Rishikesh offers majestic and
                                    unforgettable rafting among vibrant mountains and silvery rapids.
                                    From among the many options Rishikesh has to offer, adventurers
                                    looking for adrenaline-filled outdoor sports like rafting, skiing,
                                    rock climbing, or high-altitude hiking select Rishikesh as their
                                    preferred destination.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-wrapper">
                                    <div className="blog-single-content">
                                        <div className="blog-info">
                                            <div className="blog-details">
                                                <p className="mb-50 border-bottom pb-30">
                                                    A great river rafting adventure in Rishikesh involves
                                                    experiencing the divine touch of cold water, its
                                                    breath-taking views, fear of the rapids, and miles of
                                                    rafting in addition to the excitement and fun. This unique
                                                    adventure will make your trip truly unforgettable as you
                                                    raft along the bizarre river currents and plunge into the
                                                    rapids. There is unquestionably a float here for any
                                                    traveller looking for a dose of adventure, making it an
                                                    enjoyable activity to do in Rishikesh as well. One of the
                                                    most well-liked rafting routes in Rishikesh is the
                                                    Shivpuri because it offers the ideal balance of excitement
                                                    and lightness; it is neither too difficult nor too easy.
                                                    The stretch from Brahmapuri to Rishikesh-Brahmapuri is
                                                    gentle, making it a good rafting option for people of all
                                                    ages. The Brahmpuri and Shivpuri rivers in Rishikesh,
                                                    Uttarakhand, India, regularly host rafting excursions.
                                                    There are several Grade-I sections of calm water at each
                                                    rafting location in Rishikesh, providing a welcome break
                                                    from the more challenging rapids. Ages 14 to 65 can go
                                                    rafting in a few designated areas, but only in Category I
                                                    and II rapids. In Rishikesh, the rainy season
                                                    (mid-September to early summer) is the best time to go
                                                    rafting (May). When to Raft in Rishikesh is Best After the
                                                    rainy season in northern India ends in mid-September, the
                                                    best time for rafting in Rishikesh begins and lasts until
                                                    May.
                                                </p>
                                                <div className="row mb-30 justify-content-center align-items-center">
                                                    <div className="col-sm-6">
                                                        <img
                                                            src="assets/img/rafting/rafting-image-03.jpg" style={{ borderRadius: '15px' }}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h3
                                                            className="blog-details-title"
                                                            style={{ fontSize: 30 }}
                                                        >
                                                            1. Club House Rishikesh to Brahampuri NIM Beach
                                                            (Shortest Rafting- 9 Kms)
                                                        </h3>
                                                        <p>
                                                            Drive fleet vehicle from office to club house. When
                                                            you reach there, descend into the Ganges River. Begin
                                                            rafting by following the preparation and safety
                                                            instructions. Enjoy a light, gentle ride along the
                                                            Grade 2 rapids that lead you to NIM Beach. To make
                                                            your rafting trip even more enjoyable, enjoy body
                                                            surfing on this section.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row mb-30 justify-content-center align-items-center">
                                                    <div className="col-sm-6">
                                                        <h3
                                                            className="blog-details-title"
                                                            style={{ fontSize: 30 }}
                                                        >
                                                            2. Shivpuri to Rishikesh Rafting (Most Popular - 16
                                                            Kms)
                                                        </h3>
                                                        <p>
                                                            Drive to Shivpuri from office. When you reach, descend
                                                            into the Ganges River. Begin rafting by following the
                                                            preparation and safety instructions. Enjoy a gentle
                                                            ride along grade 2 rapids. To make your rafting trip
                                                            even more enjoyable, enjoy body surfing on this
                                                            section.
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <img
                                                            src="assets/img/rafting/rafting-3.jpg"
                                                            alt="" style={{ borderRadius: '15px' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-30 justify-content-center align-items-center">
                                                    <div className="col-sm-6">
                                                        <img
                                                            src="assets/img/rafting/rafting-2.jpg"
                                                            alt="" style={{ borderRadius: '15px' }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h3
                                                            className="blog-details-title"
                                                            style={{ fontSize: 30 }}
                                                        >
                                                            3. From Marine Drive to Rishikesh, by raft (26 Kms)
                                                        </h3>
                                                        <p>
                                                            Drive for 45 minutes from Rishikesh to Marine Drive,
                                                            then descend to the Ganga. Start rafting and raft down
                                                            from Marine Drive to NIM Beach in Rishikesh after
                                                            preparing and receiving safety instructions. We will
                                                            encounter rapids like Black Money, Three Blind Mice,
                                                            Crossfire, Body Surfing, Return to Sender, Golf
                                                            Course, Club House, and Double Trouble during this
                                                            section of the rafting trip. The river will run for
                                                            about 3 to 4 hours.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row mb-30 justify-content-center align-items-center">
                                                    <div className="col-sm-6">
                                                        <h3
                                                            className="blog-details-title"
                                                            style={{ fontSize: 30 }}
                                                        >
                                                            4. From Kaudiyala to Rishikesh by Raft (Longest Day
                                                            Trip of Rafting - 35 Kms)
                                                        </h3>
                                                        <p>
                                                            The journey begins at 8:30 am from our Rishikesh
                                                            office. To begin the river trip, we travel 35 km (or
                                                            about 1 hour) upstream to Kaudiyala. We get dressed in
                                                            our rafting gear and head out onto the water after
                                                            receiving a thorough safety briefing. We raft down 35
                                                            km on the big volume Ganga Whitewater (5 to 6 hours),
                                                            which includes numerous heart-pounding rapids like
                                                            Daniels Dip, The Wall, Three Blind Mice, Crossfire,
                                                            Body Surfing, Return to Sender, Roller Coaster, and
                                                            Golf course. We stop at a peaceful beach around noon
                                                            for a delicious lunch. We get back on the water after
                                                            lunch to continue navigating the significant rapids.
                                                            The journey ends at Rishikesh's NIM Beach.
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <img
                                                            src="assets/img/rafting/rafting-image-06.jpg"
                                                            alt="" style={{ borderRadius: '15px' }}
                                                        />
                                                    </div>
                                                </div>
                                                <blockquote className="blockqoute">
                                                    The purpose of life is to live it, to taste experience to
                                                    the utmost, to reach out eagerly and without fear for
                                                    newer and richer experience.
                                                </blockquote>
                                                <hr />
                                                <div className="row pt-30 pb-30">
                                                    <div className="col-sm-12">
                                                        <div className="faq-area">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-lg-6 mx-auto">
                                                                        <div className="site-heading text-center">
                                                                            <span className="site-title-tagline">
                                                                                FAQ's
                                                                            </span>
                                                                            <h2 className="site-title">
                                                                                General Question
                                                                            </h2>
                                                                            <p>
                                                                                
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-6 align-self-center">
                                                                        <div className="faq-img">
                                                                            <img
                                                                                src="assets/img/rafting/rafting-image-01.jpg"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 align-self-center">
                                                                        <div
                                                                            className="accordion"
                                                                            id="accordionExample"
                                                                        >
                                                                            <div className="accordion-item">
                                                                                <h2
                                                                                    className="accordion-header"
                                                                                    id="headingOne"
                                                                                >
                                                                                    <button
                                                                                        className="accordion-button"
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseOne"
                                                                                        aria-expanded="true"
                                                                                        aria-controls="collapseOne"
                                                                                    >
                                                                                        <i className="far fa-question" />
                                                                                        What are the rafting regulations?
                                                                                    </button>
                                                                                </h2>
                                                                                <div
                                                                                    id="collapseOne"
                                                                                    className="accordion-collapse collapse show"
                                                                                    aria-labelledby="headingOne"
                                                                                    data-bs-parent="#accordionExample"
                                                                                >
                                                                                    <div className="accordion-body">
                                                                                        Participants must be at least six years
                                                                                        old. For the duration of the race, each
                                                                                        competitor is required to wear a life
                                                                                        jacket. Without the instructor's
                                                                                        approval, the jacket must remain on. The
                                                                                        players must remain seated in the raft
                                                                                        and refrain from rough housing.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="accordion-item">
                                                                                <h2
                                                                                    className="accordion-header"
                                                                                    id="headingTwo"
                                                                                >
                                                                                    <button
                                                                                        className="accordion-button
                                      collapsed"
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseTwo"
                                                                                        aria-expanded="false"
                                                                                        aria-controls="collapseTwo"
                                                                                    >
                                                                                        <i className="far fa-question" />
                                                                                        Does rafting have a weight restriction?
                                                                                    </button>
                                                                                </h2>
                                                                                <div
                                                                                    id="collapseTwo"
                                                                                    className="accordion-collapse collapse"
                                                                                    aria-labelledby="headingTwo"
                                                                                    data-bs-parent="#accordionExample"
                                                                                >
                                                                                    <div className="accordion-body">
                                                                                        The weight range for the majority of
                                                                                        white water river rafting companies is
                                                                                        90 to 275 pounds. Although there are no
                                                                                        regulations regarding rafting weight
                                                                                        restrictions, the majority of businesses
                                                                                        demand that participants fall within
                                                                                        this range. So your ability to go river
                                                                                        rafting may be limited by your weight.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="accordion-item">
                                                                                <h2
                                                                                    className="accordion-header"
                                                                                    id="headingThree"
                                                                                >
                                                                                    <button
                                                                                        className="accordion-button
                                      collapsed"
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseThree"
                                                                                        aria-expanded="false"
                                                                                        aria-controls="collapseThree"
                                                                                    >
                                                                                        <i className="far fa-question" />
                                                                                        What time of year is ideal for river
                                                                                        rafting on the Ganga?
                                                                                    </button>
                                                                                </h2>
                                                                                <div
                                                                                    id="collapseThree"
                                                                                    className="accordion-collapse collapse"
                                                                                    aria-labelledby="headingThree"
                                                                                    data-bs-parent="#accordionExample"
                                                                                >
                                                                                    <div className="accordion-body">
                                                                                        From the middle of September until the
                                                                                        end of June is the ideal season to go
                                                                                        rafting in north India. The river
                                                                                        continues to be unsuitable for rafting
                                                                                        during the monsoon season.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>

    )
}

export default RaftingInRishikesh