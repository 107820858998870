import React from 'react'
import { Helmet } from 'react-helmet'
function Gallery() {
  return (
    <>
    <Helmet>
        <title>Him River Resort - Gallery</title>
      </Helmet>
  <main className="main">
    <div
      className="site-breadcrumb"
      style={{ background: "url(assets/img/slider/slider-bg-2.jpg)" }}
    >
      <div className="container">
        <h2 className="breadcrumb-title">Gallery</h2>
        <ul className="breadcrumb-menu">
          <li>
            <a href="index.html">Home</a>
          </li>
          <li className="active">Gallery</li>
        </ul>
      </div>
    </div>
    <div className="container pt-60 pb-100">
      <div className="row">
        <div className="col-sm-12 ">
          <h1 style={{ textAlign: "center", fontSize: 36, fontWeight: "bold" }}>
            Our Gallery
          </h1>
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-4">
          <img
            src="./assets/img/blog/gallery-0.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
        <div className="col-sm-4">
          <img src="./assets/img/blog/gallery-8.jpg" alt="" />
        </div>
        <div className="col-sm-4">
          <img
            src="./assets/img/blog/gallery-9.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-6">
          <img
            src="./assets/img/blog/gallery-12.jpg"
            alt=""
            style={{ borderRadius: 10 }}
          />
        </div>
        <div className="col-sm-6">
          <img
            src="./assets/img/blog/gallery-13.jpg"
            alt=""
            style={{ borderRadius: 10 }}
          />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-3">
          <img
            src="./assets/img/blog/resort-1.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
        <div className="col-sm-3">
          <img
            src="./assets/img/blog/resort-2.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
        <div className="col-sm-3">
          <img
            src="./assets/img/blog/resort-4.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
        <div className="col-sm-3">
          <img
            src="./assets/img/blog/why-choose.jpg"
            alt=""
            style={{ height: 203, borderRadius: 5 }}
          />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-6">
          <img src="./assets/img/gallery/gallery-image-02.jpg" style={{ borderRadius: 7 }} alt="" />
        </div>
        <div className="col-sm-6">
          <img src="./assets/img/gallery/gallery-image-03.jpg" style={{ borderRadius: 7 }} alt="" />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-12">
          <img src="./assets/img/blog/heading.jpg" style={{ borderRadius: 7 }} alt="" />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-6">
          <img
            src="./assets/img/blog/gallery-6 (1).jpg"
            alt=""
            style={{ borderRadius: 7 }}
          />
        </div>
        <div className="col-sm-6">
          <img
            src="./assets/img/blog/gallery-11.jpg"
            alt=""
            style={{ borderRadius: 7 }}
          />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-4">
          <img
            src="./assets/img/blog/camping-1.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
        <div className="col-sm-4">
          <img
            src="./assets/img/blog/rafting-1.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
        <div className="col-sm-4">
          <img
            src="./assets/img/blog/trekking-1.jpg"
            alt=""
            style={{ borderRadius: 5 }}
          />
        </div>
      </div>
      <div className="row pt-30">
        <div className="col-sm-6">
          <img
            src="./assets/img/gallery/gallery-image-00.jpg"
            alt=""
            style={{ borderRadius: 7 }}
          />
        </div>
        <div className="col-sm-6">
          <img
            src="./assets/img/gallery/gallery-image-01.jpg"
            alt=""
            style={{ borderRadius: 7 }}
          />
        </div>
      </div>
    </div>
  </main>
  {/*?php include "footer.php"
	?*/}
</>

  )
}

export default Gallery