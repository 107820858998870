import React from 'react';
import { Helmet } from 'react-helmet';

function BungeeJumping() {
  return (
    <>
      <Helmet>
        <title>
          Him River Resort - Bungee Jumping
        </title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/bungee-jumping.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Bungee Jumping In Rishikesh</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Bungee Jumping In Rishikesh</li>
            </ul>
          </div>
        </div>
        <div className="blog-single-area pt-60 pb-60">
          <div className="container">
            <div className="row">
              <h1 align="center" style={{ fontSize: 36 }} className="pb-30">
                Bungee Jumping In Rishikesh
              </h1>
              <div className="col-sm-6">
                <div className="blog-thumb-img">
                  <img src="assets/img/blog/single1.jpg" alt="thumb" />
                </div>
              </div>
              <div className="col-sm-6">
                <p>
                  Bunjee jumping Hearing "Bungee Jumping in Rishikesh" gives
                  goosebumps to many travelers across India. The city is managed by
                  the operation of the country's tallest bungee, which is supervised
                  by experienced experts. Rishikesh is not only a destination of
                  pilgrimage but is also recognized as the adventure capital of
                  India, so adventure travel tourists don't need much persuasion to
                  come here. If you're considering taking part in this activity,
                  you'll need this guide to bungee jumping. Everything is covered,
                  including price, safety concerns, location - everything and
                  location, so you don't have to study thoroughly. ng in Rishikesh
                </p>
                <p>
                  Have you ever wished that you could fly in the air like a bird?
                  Undoubtedly, bungee jumping will help with this. Bungee jumping is
                  an exciting activity that involves jumping from a height. When you
                  are jumping, a rope is holding you securely to a surface or other
                  object that can support a heavy weight.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <p> Whether you jump off a
                  plane or a cliff, you'll be completely safe. Do you know when it
                  started? It was started in August 2005. AJ Hackett made the
                  highest jump in history when he jumped from the Macau Tower. You
                  can try bungee jumping anywhere as it is a popular sport practiced
                  all over the world.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-wrapper">
                  <div className="blog-single-content">
                    <div className="blog-info">
                      <div className="blog-details">
                        <h2 className="blog-details-title mb-20">
                          Place To Bungee Jump In Rishikesh
                        </h2>
                        <p className="mb-10">
                          The best bungee jumping spot in Rishikesh is called
                          Jumping Heights, and is located in Mohanchatti. Jump
                          Masters hails from the adventure capital New Zealand, the
                          center of adventure, and the company is led by a former
                          army officer. With a height of 83 meters above the ground,
                          Jumping Heights of Rishikesh offers the highest bungee
                          leaping in all of India. Jumping Heights is open every day
                          except Tuesdays. These are considered as some of the top
                          places in India for bungee jumping.
                        </p>
                        <blockquote className="blockqoute">
                          Rishikesh was awarded the nickname of "The Adventure

                          Capital of India". This place is known for its
                          spirituality and exciting activities. And you can also do
                          river rafting, mountain biking, cliff jumping, flying fox,
                          rock climbing and parasailing etc. in Rishikesh.
                        </blockquote>
                        <h3>Concerns For Safety, Health, And Other Requirements</h3>
                        <p className="mb-20">
                          This is a question that almost everyone has pondered at
                          some point or another. Safety is undoubtedly of the utmost
                          importance when bungee jumping in Rishikesh or anywhere
                          else. However, when you're in Jumping' Heights, none of
                          that matters. In countries such as New Zealand, the hub of
                          adventure, the government has advanced professionals with
                          years of experience to supervise risky activities such as
                          bungee jumping.
                        </p>
                        <p>
                          Jumping heights meet Australian and New Zealand Standards
                          Authority criteria. The Authority is particularly
                          concerned about security, from the platform design to the
                          regular on-site exercises that the Authority is dedicated
                          to security{" "}
                        </p>
                        <p>
                          Health: Due to the severe nature of the activity, it is
                          not recommended for people who have any of the following
                          medical conditions: Epilepsy, recent fractures, back or
                          neck injuries, asthma, osteoporosis, or pregnancy
                        </p>
                        <p />
                        <p>
                          Additional requirements: To take part in this activity,
                          you must be at least 12 years old. The individual must
                          weigh in at 35–120 kg{" "}
                        </p>
                        <div className="row">
                          <div className="col-md-6 mb-20">
                            <img src="assets/img/blog/blog-12.jpg" alt="" />
                          </div>
                          <div className="col-md-6 mb-20">
                            <img src="assets/img/blog/blog-11.jpg" alt="" />
                          </div>
                        </div>
                        <h4>
                          Due to the intensive nature of this activity, the
                          following medical conditions are unacceptable
                        </h4>
                        <p className="mb-20">
                          Power of choice is untrammelled and when nothing prevents
                          our being able to do what we like best, every pleasure is
                          to be welcomed and every pain avoided. But in certain
                          circumstances and owing to the claims of duty or the
                          obligations of business it will frequently occur that
                          pleasures have to be repudiated and annoyances accepted.
                          The wise man therefore always holds in these matters to
                          this principle of selection.
                        </p>
                        <ul className="about-list list-unstyled">

                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Back or Neck injuries</p>
                            </div>
                          </li>

                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Asthma</p>
                            </div>
                          </li>

                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Epilepsy</p>
                            </div>
                          </li>

                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Pregnancy</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Recent Fracture</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Osteoporosis</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Heart Conditions</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Neurological Disorders</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>High Blood Pressure</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p> Back or Neck injuries</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Asthma</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>Epilepsy</p>
                            </div>
                          </li>
                        </ul>
                        <p />
                        <br />
                        <br />
                        <ul className="about-list list-unstyled">
                          <h4>
                            Inform the trainers of any health concerns you may have
                            before class.{" "}
                          </h4>
                          <br />
                          <br />
                          <h6>
                            {" "}
                            For those who want to go bungee jumping in Rishikesh,
                            here are a few pointers. Read on!
                          </h6>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p> Observe all of the instructor directions.</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>
                                {" "}
                                For bungee jumping, you must wear comfortable
                                clothing.
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>
                                Bring extra clothing with you Before beginning the
                                activity, have your health assessed.
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="far fa-check-circle" />
                            </div>
                            <div className="text">
                              <p>
                                As you will be guided properly by qualified
                                instructors, have faith in yourself and be
                                optimistic.
                              </p>
                            </div>
                          </li>
                        </ul>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-30 pb-30">
              <div className="col-sm-12">
                <div className="faq-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 mx-auto">
                        <div className="site-heading text-center">
                          <span className="site-title-tagline">
                            FAQ's
                          </span>
                          <h2 className="site-title">
                            General Question
                          </h2>
                          
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 align-self-center">
                        <div className="faq-img">
                          <img
                            src="assets/img/rafting/rafting-image-01.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 align-self-center">
                        <div
                          className="accordion"
                          id="accordionExample"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingOne"
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <i className="far fa-question" />
                                From where is Jump Zone located?
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">- Haridwar (Bus/Rail station): approx. 50 km (2.5 Hours) <br />
                                - Rishikesh Bus stand: around 22 kilometres (1 Hour 20 Mins) <br />
                                - Jolly Grant Airport (Dehradun): 38 Km (1 Hour 40 Minutes) <br />
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingTwo"
                            >
                              <button
                                className="accordion-button
                                      collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <i className="far fa-question" />
                                Can we eat before the bungee jump?
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">If it's comfortable for you, you can eat before any of the activities.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingThree"
                            >
                              <button
                                className="accordion-button
                                      collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <i className="far fa-question" />
                                How about photography and filmmaking? Possibly using our own Go Pro camera
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">Outside cameras are not permitted on our property. At the conclusion of the jumps, HD quality recordings and still photographs are both available for purchase.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>

  )
}

export default BungeeJumping