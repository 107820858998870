import React from 'react'

function Footer() {
    return (
        <>
            <footer
                className="footer-area pt-30"
                style={{
                    background: "url(assets/img/footer/01.jpg) no-repeat bottom center",
                    backgroundSize: "cover"
                }}
            >
                <div className="footer-widget">
                    <div className="container">
                        <div className="row footer-widget-wrapper pb-50">
                            <div className="col-md-6 col-lg-12" align="center">
                                <div className="footer-widget-box about-us">
                                    <a href="/" className="footer-logo">
                                        <img src="assets/img/logo/logo.png" style={{border:'1px solid white', padding:'10px 5px'}} alt="" />
                                    </a>
                                    <p className="mb-20">
                                        We're taking you to a place where there is river, sun, and stars.
                                    </p>
                                    <ul className="footer-social mb-30 justify-content-center align-items-center">
                                        <li>
                                            <a href="https://www.facebook.com/himriverresortss">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/himriver.resort/">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Quick Links</h4>
                                    <ul className="footer-list">
                                        <li>
                                            <a href={'/about'}>About Us</a>
                                        </li>
                                        <li>
                                            <a href={'/gallery'}>Gallery</a>
                                        </li>
                                        {/* <li><a href="#">Terms Of Service</a></li>
                      <li><a href="#">Privacy policy</a></li> */}
                                        <li>
                                            <a href={'/contact'}>Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box">
                                    <h4 className="footer-widget-title">Our Packages</h4>
                                    <ul className="footer-list">
                                        <li>
                                            <a href={'/one-night-two-day'}>1 Night/ 2 Days Package</a>
                                        </li>
                                        <li>
                                            <a href={'/two-night-three-day'}>2 Night/ 3 Days Package</a>
                                        </li>
                                        <li>
                                            <a href={'/three-nights-four-days'}>3 Night/ 4 Days Package</a>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box">
                                    <h4 className="footer-widget-title">Adventure Packages</h4>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="/camping-in-rishikesh">Camping in Rishikesh</a>
                                        </li>
                                        <li>
                                            <a href="/rafting-in-rishikesh">Rafting in Rishikesh</a>
                                        </li>
                                        <li>
                                            <a href="/bungee-jumping-in-rishikesh">Bungee in Rishikesh</a>
                                        </li>
                                        <li>
                                            <a href="/trekking-in-rishikesh">Trekking in Rishikesh</a>
                                        </li>
                                        <li>
                                            <a href="/paintball">Paintball in Rishikesh</a>
                                        </li>
                                        <li>
                                            <a href="/zipline-in-rishikesh">Zipline in Rishikesh</a>
                                        </li>
                                        <li>
                                            <a href="/flying-fox-in-rishikesh">Flying Fox in Rishikesh</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box">
                                    <h4 className="footer-widget-title">Contact Us</h4>
                                    <ul className="footer-contact">
                                        <li>
                                            <i className="far fa-map-marker-alt" />
                                            Phoolchatti, Neelkanth Temple Road, Rishikesh, Uttarakhand
                                        </li>
                                        
                                        <li>
                                            <a href="tel:9012320202">
                                                <i className="far fa-phone" style={{ transform: 'rotate(90deg)' }} />
                                                +91-90123 20202
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:himriverlife@gmail.com">
                                                <i className="far fa-envelope" />
                                                <span
                                                    className="__cf_email__"
                                                    data-cfemail="c5acaba3aa85a0bda4a8b5a9a0eba6aaa8"
                                                >
                                                    himriverlife@gmail.com
                                                </span>
                                            </a>
                                        </li>
                                        {/* <li><i class="far fa-clock"></i>Sun - Fri (10AM - 08PM)</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <p className="copyright-text">
                            © Copyright <span id="date" /> <a href="#"> Riddhika Developers </a>{" "}
                            All Rights Reserved.
                        </p>
                    </div>
                </div>
            </footer>
            <a href="#" id="scroll-top">
                <i className="far fa-angle-double-up" />
            </a>
        </>

    )
}

export default Footer