import React from 'react';
import { Helmet } from 'react-helmet';

function ResortInRishikesh() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - Resort in Rishikesh</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/slider/slider-bg-2.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Resort In Rishikesh</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li className="active">Resort In Rishikesh</li>
            </ul>
          </div>
        </div>
        <div className="blog-single-area pt-60 pb-60">
          <div className="container">
            <div className="row mb-10">
              <div className="col-md-12">
                <h1
                  align="center"
                  style={{ fontSize: 36, textTransform: "uppercase" }}
                >
                  Resort In Rishikesh
                </h1>
                <br />
                <div className="bolg-image" style={{ border: "2px solid #000" }}>
                  <img
                    src="./assets/img/blog/heading.jpg"
                    alt=""
                    style={{ padding: 10 }}
                  />
                </div>
                <br />
                <div className="about-resort" style={{ textAlign: "justify" }}>
                  <p>
                    The Him River Resort, which has Deluxe cabins, is located on the
                    boundaries of Rajaji National Park, close to the Heul River in a
                    peaceful valley. The cabin is within a minute's walk from the
                    river. In Rishikesh (Himalayas), Him River Resort makes a great
                    base for outdoor activities.
                  </p>
                  <p>
                    It is located 8 kilometres from Rishikesh on a quiet river bank,
                    far from the loud, polluting city. Here, you can enjoy the
                    calmness and peace of the magnificent countryside, the clean air
                    wind, the calming sound of gushing white water, and the melodic
                    sound of chirping birds.
                  </p>
                  <p>
                    Just 6 kilometres from Luxmanjhula (near the Rapid golf course)
                    and close to the phoolchatti ashram, which is situated along an
                    old pilgrimage route into the sources of the Ganga. Him River
                    Resort Rishkesh, which is surrounded by mountains on all four
                    sides, offers distinctive Clay Cottages that are designed in a
                    straightforward yet comfortable style for a couple or family,
                    complete with 24-hour electricity, an attached bathroom, and
                    running water. Every guest will undoubtedly enjoy their stay
                    thanks to the thoughtful design of our cottages.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="blog-thumb-img">
                  <img
                    src="assets/img/blog/resort-img-1.jpg"
                    alt="thumb"
                    style={{
                      borderRadius: 5,
                      boxShadow: "0 .5rem 1rem rgba(0,0,0,.2)"
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4" style={{ textAlign: "justify" }}>
                <p>
                  Him River Resort is designed to make your trip a special, relaxing
                  experience that you won't soon forget. Our prices are reasonable,
                  our accommodations are spacious, and our service is unmatched.
                </p>
                <p>
                  Traveling can be exhausting, so our staff will do everything
                  possible to make your stay comfortable and enjoyable. Visit our
                  website to learn more about our accommodation, food and other
                  facilities, and contact us if there is anything else we can do to
                  help you. We are excited to see you soon!
                </p>
                <p>
                  Him River Resort is located at the nexus of convenience and
                  comfort, where you can fully benefit from a wide range of
                  amenities and services that are sure to make your time with us
                  memorable.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src="assets/img/blog/resort-img-2.jpg"
                  alt="thumb"
                  style={{
                    borderRadius: 5,
                    boxShadow: "0 .5rem 1rem rgba(0,0,0,.3)"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid mb-80"
          style={{ backgroundColor: "#eeeeee" }}
        >
          <div className="row pb-40 pt-40">
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <h3 style={{ textTransform: "uppercase", color: "red" }}>
                why-choose Him River resort
              </h3>
              <h5>
                The reason why our customers appreciate us is thanks to these Major
                Qualities of ours.
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="center-div" style={{ textAlign: "center" }}>
                <div className="icon-img">
                  <img
                    src="./assets/img/blog/24-hours.png"
                    alt=""
                    style={{ width: 100, height: 100, marginBottom: 10 }}
                  />
                  <h5>Fast Service</h5>
                  <p>
                    You put all of your troubles aside while enjoying Him River
                    Resort. We're here to support you.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="center-div" style={{ textAlign: "center" }}>
                <div className="icon-img">
                  <img
                    src="./assets/img/blog/man.png"
                    alt=""
                    style={{ width: 100, height: 100, marginBottom: 10 }}
                  />
                  <h5>Knowledgeable Team</h5>
                  <p>Our team is very knowledgeable about the Himalayan region.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="center-div" style={{ textAlign: "center" }}>
                <div className="icon-img">
                  <img
                    src="./assets/img/blog/target.png"
                    alt=""
                    style={{ width: 100, height: 100, marginBottom: 10 }}
                  />
                  <h5>Our goal is to provide better service</h5>
                  <p>
                    Our goal is to rapidly supply our "Customers" with consistency
                    and excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row pb-40">
            <div className="col-md-4">
              <div className="center-div" style={{ textAlign: "center" }}>
                <div className="icon-img">
                  <img
                    src="./assets/img/blog/star.png"
                    alt=""
                    style={{ width: 100, height: 100, marginBottom: 10 }}
                  />
                  <h5>Best price guarantee</h5>
                  <p>
                    To ensure your satisfaction, we offer you the greatest pricing
                    ever. Our customers are always loved by us. For us, your smile
                    is everything.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="center-div" style={{ textAlign: "center" }}>
                <div className="icon-img">
                  <img
                    src="./assets/img/blog/tour-guide.png"
                    alt=""
                    style={{ width: 100, height: 100, marginBottom: 10 }}
                  />
                  <h5>Experienced local guides</h5>
                  <p>
                    We provide you our most knowledgeable and trustworthy local
                    guides. They will aid you in learning all there is to know about
                    Rishikesh.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="center-div" style={{ textAlign: "center" }}>
                <div className="icon-img">
                  <img
                    src="./assets/img/blog/certificate.png"
                    alt=""
                    style={{ width: 100, height: 100, marginBottom: 10 }}
                  />
                  <h5>Excellence Certificate</h5>
                  <p>
                    We are Rishikesh's top service company. We are constantly
                    prepared to provide our clients with superior services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pb-30">
            <div className="col-md-12">
              <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
                OUR RESORT facilities / Service
              </h3>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <ul>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong> Room Service </strong>
                </li>
                <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                <strong>Free Wi-Fi</strong>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>A Dining Area</strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>Safety and Security for You</strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>A Meditation Room</strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>An Activity Centre </strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>Bike Rental and Car Rental are Available</strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>Parking Available</strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>Riverside Camp</strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>Meals: Breakfast - Lunch - Dinner</strong>
                </li>
                <li>
                  <i className="fa-solid fa-play" style={{ padding: "8px 15px" }} />
                  <strong>Doctor On call</strong>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <img src="./assets/img/blog/why-choose.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="blog-single-wrapper">
                <div className="blog-single-content">
                  <div className="blog-info">
                    <div className="blog-details">
                      <blockquote className="blockqoute">
                        It is said that life comes once, so why not live it like an
                        adventure. Every new day brings a new hope. Whenever you are
                        looking for peace, come to these mountains in search of
                        yourself away from the world.
                        <h6 className="blockqoute-author">Aman Payaal</h6>
                      </blockquote>
                      <div className="row">
                        <div className="col-md-6 mb-20">
                          <img src="assets/img/blog/resort-4.jpg" alt="" />
                        </div>
                        <div className="col-md-6 mb-20">
                          <img src="assets/img/blog/resort-1.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-30">
          <div className="row pb-20" style={{ textAlign: "center" }}>
            <h3>ATTRACTIONS</h3>
            <h5>Rishikesh(Uttarakhand)</h5>
            <div className="col-sm-12">
              <img src="./assets/img/blog/attraction.jpg" alt="" />
              <a
                href="/places"
                className="theme-btn"
                style={{ marginTop: "-100px" }}
              >
                Click Now <i className="fa-solid fa-arrow-right" />
              </a>
            </div>
          </div>
          <hr />
        </div>
        <div className="container faq-area">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">FAQ's</span>
                <h2 className="site-title">General Question</h2>
                <p>
                  It is a long established fact that a reader will be distracted by
                  the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-30">
            <div className="col-lg-12 align-self-center">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <i className="far fa-question" /> What are the Services
                      provided at Him River Resort Rishikesh ?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The Him River Resort Rishikesh offers the following Services
                      (Charges may apply)
                      <ul>
                        <li>1-Luggage storage</li>
                        <li>2-Front desk</li>
                        <li>3-24 Hour Power Supply</li>
                        <li>4-Room service</li>
                        <li>5-Activities</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <i className="far fa-question" /> Does Him River Resort
                      Rishikesh have any parking space?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes, Him River Resort Rishikesh has a free parking facility.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <i className="far fa-question" />
                      What are the Check - In and Check - Out timings at Phool
                      Chatti Resort Rishikesh ?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The Check- In Time at Him Riveri Resort Rishikesh is From
                      01:00 PM and the Check-Out Time is until 10:00 AM
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>

  )
}

export default ResortInRishikesh