import React from 'react';
import { Helmet } from 'react-helmet';

function Paintball() {
  return (
    <>
      <Helmet>
        <title>Him River Resort - Pintball</title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/paint-ball.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">PaintBall in Rishikesh </h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li className="active">PaintBall In Rishikesh Package</li>
            </ul>
          </div>
        </div>
        <div
          className="container"
          style={{ boxShadow: "0 .5rem 1rem rgba(0,0,0,.2)" }}
        >
          <div className="row pt-40 pb-40">
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <h1 style={{ fontSize: '36px' }}>
                <strong>-PaintBall In Rishikesh Package-</strong>
              </h1>
            </div>
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div className="col-md-8" style={{ textAlign: "center" }}>
                <p style={{ textAlign: "center", color: "red", fontSize: 20 }}>
                  <strong>
                    We provide a choice of adventure activities in Rishikesh, like
                    River Rafting, Bungee Jumping, Camping, Flying Fox, Trekking,
                    and Paintball, to go along with vacations or business trips.
                  </strong>{" "}
                </p>
                <a href={'/contact'} className="theme-btn">
                  Book Now
                </a>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div
              className="col-md-12"
              style={{ backgroundColor: "orange", padding: 15, color: "white" }}
            >
              <h5 style={{ lineHeight: 2, textAlign: "center" }}>
                <strong>
                  {" "}
                  "In paintball, the player is not the only important person. It's
                  about community, respect for everyone who participates in this
                  thrilling activity, and friendship."
                </strong>
              </h5>
            </div>
          </div>
          <div className="row pt-60" style={{ margin: 0 }}>
            <div className="col-md-6">
              <img
                src="./assets/img/blog/paintball-2.jpg"
                alt=""
                style={{ boxShadow: "0 .5rem 1rem rgba(0,0,0,.2)" }}
              />
            </div>
            <div className="col-md-6">
              <p style={{ textAlign: "justify" }}>
                In Rishikesh, paintball is a fascinating sport to do. The town draws
                visitors with its fantastic camp packages and exciting activities.
                Visitors from all over the world come to Rishikesh to experience the
                thrill of its sporting adventures and leisure pursuits. Their
                mundane daily lives demand change, so they are always browsing
                travel and tourist websites to look for beautiful possibilities. The
                paintball adventure stands out among all the spine-tinglingly
                thrilling adventurous pursuits since it demands more competitive
                spirit than it does an adrenaline rush.
              </p>
              <p style={{ textAlign: "justify" }}>
                Rishikesh is a destination open to anyone. The youngsters and
                individuals of all ages are thrilled and excited by these
                fascinating recreational activities. When these young individuals
                participate in their real tasks, the energising influence of
                paintball is also seen in the working manner. They get knowledge
                about teamwork and concentrating on one's advantages.
              </p>
            </div>
            <div className="row pt-20">
              <div className="col-md-6">
                <p style={{ textAlign: "justify" }} />
                <p style={{ textAlign: "justify" }}>
                  This paintball warfare exercise is a huge hit with corporate
                  groups and students since it improves teamwork and morale for
                  everyone on the team. During the fictitious battle, the
                  participants participate by devising plans and providing cover.
                  The key elements of the adventure are vigilance, adaptability,
                  teamwork, and trust. This challenging adventure is beneficial for
                  both your physical and emotional health. It encourages everyone in
                  the group to put forth their best effort and aids in their escape
                  from the stressful environment of the workplace or the classroom.
                  Young boys and girls can be seen running, yelling, and hooting all
                  over the activity area.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We provide the very popular adventure sport of paintball for the
                  first time in Rishikesh, Uttarakhand. Paintball is a blood, sweat,
                  and tears sport of speed, stealth, and strategy. Youth are being
                  weaned off of their Xboxes and encouraged to play paintball
                  instead, which has all the chills and thrills of a battle without
                  the pain and discomfort. Peek-a-boo in the current day is called
                  paintball adventure activity.
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src="./assets/img/blog/paintball-3.jpg"
                  alt=""
                  style={{ boxShadow: "0 .5rem 1rem rgba(0,0,0,.2)" }}
                />
              </div>
            </div>
          </div>
          <div className="row pt-20">
            <div className="col-md-12">
              <p style={{ textAlign: "justify" }}>
                <strong>Team building: </strong>A team-building activity is
                paintball. We provide a facility for team-building exercises in
                Rishikesh, India. Participate in the Paintball Adventure with Him
                River Resort.
              </p>
            </div>
          </div>
          <div className="row pt-30">
            <div className="col-md-12">
              <h4 style={{ fontWeight: "bold" }}>
                About Duration, Person, Timings of Paintball in Rishikesh:-
              </h4>
              <ul>
                <li>
                  <strong>1</strong> - The length of the game is 40–44 minutes.
                </li>
                <li>
                  <strong>2</strong> - To play, there must be a minimum of 4 players
                  and a maximum of 10.
                </li>
                <li>
                  <strong>3</strong> - This game can be played on the same field by
                  100 players (in one group 10 people).
                </li>
                <li>
                  <strong>4</strong> - Game timing: 6am to 7pm in the morning.
                </li>
                <li>
                  <strong>5</strong> - Markers, a face mask, a chest guard, hoppers,
                  and a CO2 cylinder were used as equipment.
                </li>
              </ul>
            </div>
          </div><hr />
          <div className="container faq-area">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="site-heading text-center">
                  <span className="site-title-tagline">FAQ's</span>
                  <h2 className="site-title">General Question</h2>
                 
                </div>
              </div>
            </div>
            <div className="row mb-30">
              <div className="col-lg-12 align-self-center">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <i className="far fa-question" />
                        Are paintballs washable?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        'Yes!' – paintballs are water-soluble, non-toxic and will
                        wash out of your clothing on a normal wash cycle.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <i className="far fa-question" />
                        How many people play paintball?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        This game can be played on the same field by 100 players (in
                        one group 10 people).
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <i className="far fa-question" />
                        Are paintballs water-soluble?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes! Paintballs are made to be environmentally friendly,
                        non-toxic, water-soluble, and biodegradable.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*?php include "footer.php"
	?*/}
    </>

  )
}

export default Paintball