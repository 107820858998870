import React from 'react';
import { Helmet } from 'react-helmet';

function TreekingInRishikesh() {
  return (
    <>
      <Helmet>
        <title>
          Him River Resort - Trekking in Rishikesh
        </title>
      </Helmet>
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/bg/trekking.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Trekking In Rishikesh</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li className="active">Trekking In Rishikesh</li>
            </ul>
          </div>
        </div>

        <div className="blog-single-area pt-60 pb-60">
          <div className="container">
            <div className="row">
              <h1 align="center" style={{ fontSize: 36 }}>
                Trekking In Rishikesh
              </h1>
            </div>
            <div className="row pb-30">
              <div className="col-md-12">
                <blockquote className="blockqoute" style={{ backgroundColor: "#fca702" }}>
                  "Hiking is realism; it's not escapism. People who choose to spend time
                  outside aren't trying to escape anything; rather, we're going back to our
                  natural habitat."
                </blockquote>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="blog-thumb-img">
                  <img src="assets/img/blog/trekking.jpg" alt="thumb" />
                </div>
              </div>
              <div className="col-sm-6">
                <p>
                  Trekking to the Waterfall is one of the most thrilling activities
                  one can look forward to when visiting Rishikesh. In the area of
                  Rishikesh, there are several undiscovered waterfalls. The Rajaji
                  National Park, a very well-known protected forest, surrounds the
                  city of Rishikesh, and it has a number of secret waterfalls.Due to
                  the difficulty of reaching these waterfalls, which require a
                  journey, they are well-preserved and exhibit distinctive natural
                  beauty. There are limestone caves close to a few of the waterfalls
                  as well. Neergadhu Waterfall, Garud Chatti Waterfall, and Patna
                  Waterfall are three of the most stunning waterfalls in the area of
                  Rishikesh. Trekking to the waterfall requires travelling through
                  the forest on rocky pathways, which makes it an adventurous
                  exercise. Once you arrive at the waterfall, all the effort and
                  walking seem worthwhile because of the amazing splendour of these
                  waterfalls. Standing close to the waterfall is quite calming.
                </p>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  Some of the most breathtaking and intriguing places to visit in
                  Rishikesh (Uttarakhand) are the vast Himalayan mountain ranges.
                  They can also be the most inaccessible and challenging locations
                  to reach, and the only practical method to visit them is on foot.
                  This is because they are frequently not served by highways.
                  Trekking may be a goal in itself for some individuals, a way to
                  experience the breathtaking views for others, and frequently the
                  locals of the mountains, with their cultures, customs, and faiths,
                  offer just as much fascination as the landscape.
                </div>
              </div>
            </div>
            <div className="row  pt-30">
              <div className="col-lg-12">
                <div className="blog-single-wrapper">
                  <div className="blog-single-content">
                    <div className="blog-info">
                      <div className="blog-details">
                        <h2 className="blog-details-title">
                          Waterfalls' distance from Rishikesh
                        </h2>
                        <br />
                        <div className="row">
                          <h3 style={{ fontSize: 25 }}>Neergadhu Waterfall</h3>
                          <p>
                            Three kilometres from Laxman Jhula on the Badrinath
                            Highway, the trek to the Neergadhu Waterfall begins.
                            Being the easiest to reach, it is one of the waterfalls
                            that is most frequently visited. There is also a small
                            pool where people can take a bath.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-20">
                            <img
                              src="assets/img/waterfall/neergadhu-waterfall.jpg"
                              alt=""
                            />
                          </div>
                          <div className="col-md-6 mb-20">
                            <img
                              src="assets/img/waterfall/garud-chatti-waterfall.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <br />
                        <h3 style={{ fontSize: 25 }}>Garud Chatti Waterfall</h3>
                        <p className="mb-20">
                          On the Neelkanth Mandir Road, about 4 kilometres from
                          Laxman Jhula, is where the trek to Garud Chatti Waterfall
                          begins. The Garud God is honoured in the temple where the
                          trek begins. The monsoon season, when the waterfall's
                          water flows through its seven distinct levels, is when it
                          is most beautiful.
                        </p>
                        <br />
                        <div className="row">
                          <h3 style={{ fontSize: 25 }}>Patna Waterfall</h3>
                          <div className="col-sm-6">
                            <div className="blog-thumb-img">
                              <img
                                src="assets/img/waterfall/patna-waterfall.jpg"
                                alt="thumb"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <p>
                              The Patna waterfall is named after the small hamlet of
                              the same name that it is on the way to. On Neelkanth
                              Mandir Road, five kilometres from Laxman Jhula, is
                              where the hike to the waterfall begins. The 1.5 km
                              trek through Rajaji Forest is stunning.
                            </p>
                            <p>
                              {" "}
                              The limestone caves next to the waterfall are another
                              reason for its fame. There are legends connected to it
                              that claim one of the caves is home to a temple and an
                              idol of Lord Vishnu.{" "}
                            </p>
                            <p>
                              {" "}
                              Although the hike to the waterfall can be challenging,
                              especially in the summer, the natural beauty, sound of
                              the flowing water, and cool breeze are sure to
                              mesmerise and leave you speechless once you
                              arrive.Come here with your friends, families and enjoy
                              your memorable moments and take pictures for lifelong
                              memories while on your Rishikesh waterfall trek.
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="faq-area pt-60">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6 mx-auto">
                                <div className="site-heading text-center">
                                  <span className="site-title-tagline">FAQ's</span>
                                  <h2 className="site-title">General Question</h2>
                                  <p>
                                    confused? Let us respond to all of your queries.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 align-self-center">
                                <div className="faq-img">
                                  <img src="assets/img/faq/faq1.jpg" alt="" />
                                </div>
                              </div>
                              <div className="col-lg-6 align-self-center">
                                <div className="accordion" id="accordionExample">
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        <i className="far fa-question" /> When is
                                        the ideal time to book a trip to Rishikesh
                                        for adventure?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        The ideal time to book a trip for an
                                        adventure to Rishikesh is during the winter,
                                        from October to March. The weather is still
                                        cold at this time, but Rishikesh is still
                                        breathtakingly beautiful.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        <i className="far fa-question" /> What is
                                        best place for Treeking in uttrakhand?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        Uttarakhand is a adventurous city and in
                                        rishikesh there are many trekking spots. Him
                                        River Resort provide best places for
                                        trekking. Your adventure become memorable if
                                        you choose Him River Resort.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        <i className="far fa-question" /> Is
                                        Trekking safe or not?
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        In general, trekking is very rewarding and a
                                        once-in-a-lifetime experience. While
                                        trekking is not dangerous, it is still
                                        important to always consider your health and
                                        safety. And there is nothing to worry about
                                        trekking. Him River Resort takes full care
                                        of your safety.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*?php include "footer.php"
    ?*/}
    </>

  )
}

export default TreekingInRishikesh