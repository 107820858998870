import React from 'react'

function Header() {
  return (
    <header className="home-3 header">
  <div className="top-header">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-7 col-12">
          <div className="top-header-left">
            <div className="top-contact-info">
              <ul>
                <li>
                  <a href="tel:+91 90123 20202">
                    <i className="fas fa-phone" style={{transform:'rotate(90deg)'}}/>
                    +91 90123 20202
                  </a>
                </li>
                <li>
                  <a href="/cdn-cgi/l/email-protection#e0898e868fa08598818d908c85ce838f8d">
                    <i className="fas fa-envelope" />
                    <span
                      className="__cf_email__"
                      data-cfemail="f0999e969fb09588919d809c95de939f9d"
                    >
                      himriverlife@gmail.com
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-12">
          <div className="top-header-right">
            <div className="top-social">
              <a href="https://www.facebook.com/himriverresortss">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
              <a href="https://www.instagram.com/himriver.resort">
                <i className="fab fa-instagram" />
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="main-navigation">
    <nav className="navbar navbar-expand-lg">
      <div className="container g-0">
        <a className="navbar-brand" href="/">
          <img
            src="assets/img/logo/logo.png"
            className="logo-display"
            alt="thumb"
          />
          <img
            src="assets/img/logo/logo-dark.png"
            className="logo-scrolled"
            alt="thumb"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="far fa-bars" />
          </span>
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={'/about'}>
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/gallery">
                Gallery
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
              >
                Packages
              </a>
              <ul className="dropdown-menu fade-up">
                <li>
                  <a className="dropdown-item" href="/one-night-two-day">
                    1 Night / 2 Days Package
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/two-night-three-day">
                    2 Night / 3 Days Package
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/three-nights-four-days">
                    3 Night / 4 Days Package
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
              >
                Adventure Packages
              </a>
              <ul className="dropdown-menu fade-up">
                <li>
                  <a className="dropdown-item" href="/rafting-in-rishikesh">
                    Rafting in Rishikesh Package
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/camping-in-rishikesh">
                    Camping in Rishikesh Package
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/bungee-jumping-in-rishikesh">
                    Bungee in Rishikesh Package
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/Paintball"
                  >
                    PaintBall in Rishikesh Package
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/trekking-in-rishikesh">
                    Trekking in Rishikesh Package
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/zipline-in-rishikesh">
                    Zipline in Rishikesh Package
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/flying-fox-in-rishikesh">
                    Flying Fox in Rishikesh Package
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={'/contact'}>
                Contact
              </a>
            </li>
          </ul>
          <div className="header-btn">
            <a href="tel:+91 90123 20202" className="theme-btn">
              CALL NOW
              <i className="fas fa-arrow-right" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>

  )
}

export default Header