import React from 'react';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <>
      <Helmet>
        <title>Him River Resort</title>
      </Helmet>
      <main className="home-3 main">
        <div
          className="hero-single"
          style={{ backgroundImage: "url(assets/img/slider/slide-bg-5.jpg)" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-1 col-lg-1" />
              <div className="col-md-8 col-lg-8">
                <div className="hero-content">
                  <h6
                    className="hero-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".25s"
                  >
                    Welcome ! To Him River Resort
                  </h6>
                  <h1
                    className="hero-title wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".50s"
                  >
                    Reserve Today and Enjoy Your Stay
                  </h1>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".75s"
                   style={{width:'85%'}} align="justify">
                    "Vacation helps relieve stress and boredom, gives us a change of scenery, gives us adventure, and helps us get closer to the people in our lives. Snow River Resort Connects You With Nature If you are looking for a good resort then Him River Resort is always ready to welcome you."
                  </p>
                  <div
                    className="hero-btn wow animate__animated animate__fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <a href={'/contact'} className="theme-btn">
                      Contact Us <i className="fas fa-arrow-right" />
                    </a>
                    <a href={'/about'} className="theme-btn border-btn">
                      About Us <i className="fas fa-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-area">
          <div className="container">
            <div className="search-form-wrapper">
              <form action="#">
                <div className="row align-items-end">
                  <div className="col-md-6 col-lg-2 search-input">
                    <div className="form-group">
                      <label>Check In</label>
                      <input
                        type="text"
                        className="form-control date-picker"
                        placeholder="MM / DD / YY"
                      />
                      <div>
                        <i className="far fa-calendar-alt" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2 search-input">
                    <div className="form-group">
                      <label>Check Out</label>
                      <input
                        type="text"
                        className="form-control date-picker"
                        placeholder="MM / DD / YY"
                      />
                      <div>
                        <i className="far fa-calendar-alt" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2 search-input">
                    <div className="form-group">
                      <label>Rooms</label>
                      <select className="select form-control">
                        <option value={1}>1 Rooms</option>
                        <option value={2}>2 Rooms</option>
                        <option value={3}>3 Rooms</option>
                        <option value={4}>4 Rooms</option>
                        <option value={5}>5 Rooms</option>
                        <option value={6}>6 Rooms</option>
                      </select>
                      <div className="nice-select-icon">
                        <i className="far fa-blanket" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2 search-input">
                    <div className="form-group">
                      <label>Adults</label>
                      <select className="select form-control">
                        <option value={1}>1 Adults</option>
                        <option value={2}>2 Adults</option>
                        <option value={3}>3 Adults</option>
                        <option value={4}>4 Adults</option>
                        <option value={5}>5 Adults</option>
                        <option value={6}>6 Adults</option>
                      </select>
                      <div className="nice-select-icon">
                        <i className="far fa-user" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2 search-input">
                    <div className="form-group">
                      <label>Childs</label>
                      <select className="select form-control">
                        <option value={1}>1 Childs</option>
                        <option value={2}>2 Childs</option>
                        <option value={3}>3 Childs</option>
                        <option value={4}>4 Childs</option>
                        <option value={5}>5 Childs</option>
                        <option value={6}>6 Childs</option>
                      </select>
                      <div className="nice-select-icon">
                        <i className="far fa-child" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2 search-input">
                    <button type="submit" className="search-btn">
                      {" "}
                      <i className="far fa-search" /> Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="about-area py-120 ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-left">
                  <div className="about-img">
                    <img
                      className="about-img-1"
                      src="assets/img/about/1.jpg"
                      alt=""
                    />
                    <img
                      className="about-img-2"
                      src="assets/img/about/2.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-right">
                  <div className="site-header">
                    <span className="site-title-tagline">About Us</span>
                    <h2 className="site-title">
                      We Provide Best Place To Enjoy Your Time
                    </h2>
                  </div>
                  <p className="about-text">
                    Him River Resort is designed to make your trip a special, relaxing experience that you won't soon forget. Our prices are reasonable, our accommodations are spacious, and our service is unmatched.
                  </p>
                  <div className="about-list-wrapper">
                    <ul className="about-list list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="far fa-check-circle" />
                        </div>
                        <div className="text">
                          <p>We take care of every convenience so they can focus solely on</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="far fa-check-circle" />
                        </div>
                        <div className="text">
                          <p>At Him River Resort, we work hard to ensure that all our visitors have a successful trip</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="far fa-check-circle" />
                        </div>
                        <div className="text">
                          <p>
                            We are above and beyond what Him River Resorts in Rishikesh offers in terms of personalized service and hospitality.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <a href={'/about'} className="theme">
                    Know More <i className="far fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="room-area pt-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="site-heading text-center">
                  <span className="site-title-tagline">Rooms</span>
                  <h2 className="site-title">Best Rooms</h2>
                  <p>This resort is situated on the hills, in this luxury resort you get a view of nature and we give you good facilities. If you are looking for a good resort then Him River Resort should be your first priority.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="room-item">
                  <div className="room-img">
                    <span className="room-badge">Popular</span>
                    <img src="assets/img/room/room-01.jpg" alt="" />
                    <a href="#" className="room-wish">
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <div className="room-content">
                    <div className="room-title">
                      <a href="#">
                        <h6 className='fw-bold'>Premium Cottage</h6>
                      </a>
              
                    </div>
                    <div className="room-price">
                      <span>
                        <strong>₹7000</strong> / Per Person
                      </span>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/contact'} className="room-btn">
                        Book Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/'} className="room">
                        Room Info<i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="room-item">
                  <div className="room-img">
                    <span className="room-badge">Popular</span>
                    <img src="assets/img/room/room-02.jpg" alt="" />
                    <a href="#" className="room-wish">
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <div className="room-content">
                    <div className="room-title">
                      <a href="#">
                        <h6 className='fw-bold'>Sharing Standard Cottage</h6>
                      </a>
                      
                    </div>
                    <div className="room-price">
                      <span>
                        <strong>₹4000</strong> / Per Person
                      </span>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/contact'} className="room-btn">
                        Book Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/'} className="room">
                        Room Info<i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="room-item">
                  <div className="room-img">
                    <span className="room-badge">Popular</span>
                    <img src="assets/img/room/room-03.jpg" alt="" />
                    <a href="#" className="room-wish">
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <div className="room-content">
                    <div className="room-title">
                      <a href="#">
                        <h6 className='fw-bold '>Mud House Cottage</h6>
                      </a>
                    </div>
                    <div className="room-price">
                      <span>
                        <strong>₹3500</strong> / Per Person
                      </span>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/contact'} className="room-btn">
                        Book Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/'} className="room">
                        Room Info<i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="room-item">
                  <div className="room-img">
                    <span className="room-badge">Popular</span>
                    <img src="assets/img/room/room-04.jpg" alt="" />
                    <a href="#" className="room-wish">
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  
                  <div className="room-content">
                    <div className="room-title">
                      <a href="#">
                        <h6 className='fw-bold '>Deluxe Cottage</h6>
                      </a>
                    </div>
                    <div className="room-price">
                      <span>
                        <strong>₹4000</strong> / Per Person
                      </span>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/contact'} className="room-btn">
                        Book Now <i className="far fa-arrow-right" />
                      </a>
                    </div>
                    <div className="footer-bottom d-grid gap-3 text-center">
                      <a href={'/'} className="room">
                        Room Info<i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container-fluid">
            <div className="row partner-area partner-bg justify-content-center align-items-center">
              <div className="col-sm-6">
                <div className="site-heading pt-30">
                  <span className="site-title-tagline text-white">Packages</span>
                  <h2 className="site-title text-white">Camping Packages</h2>
                  <p className="text-white" align="justify">
                    Him River Resort is inspired by local nature and designed to make
                    your vacation special, comfortable and enjoyable. Our accommodations
                    are large, our rates are reasonable, and our service is the best.
                  </p>
                  <p className="text-white" align="justify">
                    Our staff understands that traveling can be difficult, and they will
                    do everything possible to make your stay comfortable and enjoyable.
                    Him River Resort is located at the crossroads of comfort and
                    convenience, where you can take use of a variety of amenities and
                    services that are sure to make your stay with us remarkable.
                  </p>
                  <p className="text-white" align="justify">
                    From guest services to tour packages, we strive to give you with the
                    care and luxury you deserve. We guarantee you'll have a good visit
                    here. Explore our website to learn More about our accommodations,
                    food, and facilities and please contact us if you have any
                    questions.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <img src="assets\img\gallery\main-image.jpg" />
              </div>
            </div>
          </div>
          <div className="service-area pt-120">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mx-auto">
                  <div className="site-heading text-center">
                    <span className="site-title-tagline">Packages</span>
                    <h2 className="site-title">Our Packages</h2>
                    <p>
                      Him River Resort is available in Rishikesh. This allows you to
                      participate in various activities such as camping, trekking, and
                      so on. Our some packages are
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="service-item">
                    <div className="service-img">
                      <img src="assets\img\gallery\rafting-inner-blog.jpg" style={{ width: '100%', height: '425px', objectFit: 'cover' }} alt="" />
                    </div>
                    <div className="service-content">
                      <h4>Rafting</h4>
                      <p>
                        Rafting is the "Fun, Adventure, and The Great Outdoor
                        Experience" in Rishikesh.
                      </p>
                      <a href={'/rafting-in-rishikesh'} className="theme-btn">
                        Know More <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="service-item">
                    <div className="service-img">
                      <img
                        src="assets\img\gallery\bungee-jumping-inner-blog.jpg"
                        alt="" style={{ width: '100%', height: '425px', objectFit: 'cover' }}
                      />
                    </div>
                    <div className="service-content">
                      <h4>Bungee Jumping</h4>
                      <p>
                        The adventure sport of "Bungee Jumping" requires jumping from a
                        great height.
                      </p>
                      <a href={'/bungee-jumping-in-rishikesh'} className="theme-btn">
                        Know More <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="service-item">
                    <div className="service-img">
                      <img src="assets\img\gallery\trekking-in-rishikesh.jpg" alt="" style={{ width: '100%', height: '425px', objectFit: 'cover' }} />
                    </div>
                    <div className="service-content">
                      <h4>Trekking</h4>
                      <p>
                        Trekking sites in the Himalayan foothills are each unique in
                        Rishikesh.
                      </p>
                      <a href={'/trekking-in-rishikesh'} className="theme-btn">
                        Know More <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <section>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                      <div className="service-img">
                        <img src="assets\img\gallery\camping-inner-blog.jpg" alt="" style={{ width: '100%', height: '425px', objectFit: 'cover' }} />
                      </div>
                      <div className="service-content">
                        <h4>Camping</h4>
                        <p>
                          Rishikesh is one of the most beautiful camping destinations in
                          India.
                        </p>
                        <a href={'/camping-in-rishikesh'} className="theme-btn">
                          Know More <i className="far fa-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                      <div className="service-img">
                        <img
                          src="assets\img\gallery\flying-fox-inner-blog.jpeg" style={{ width: '100%', height: '425px', objectFit: 'cover' }}
                          alt=""
                        />
                      </div>
                      <div className="service-content">
                        <h4>Flying Fox</h4>
                        <p>
                          It's time to ride India's Longest Flying Fox, a thrilling 1250
                          foot ride.
                        </p>
                        <a href="/flying-fox-in-rishikesh" className="theme-btn">
                          Know More <i className="far fa-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="service-item">
                      <div className="service-img">
                        <img src="assets\img\gallery\Zip-Line-inner-blog.jpg" style={{ width: '100%', height: '425px', objectFit: 'cover' }} alt="" />
                      </div>
                      <div className="service-content">
                        <h4>Zipline</h4>
                        <p>
                          Ziplining is becoming increasingly popular among thrill
                          seekers.
                        </p>
                        <a href={'/zipline-in-rishikesh'} className="theme-btn">
                          Know More <i className="far fa-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        <div className="testimonial-area testimonial-bg py-120 mt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="site-heading text-center">
                  <span className="site-title-tagline">Testimonials</span>
                  <h2 className="site-title text-white">What Client Say's</h2>
                  <p className="text-white">
                    Him River Resort is designed to make your stay memorable, enjoyable
                    and enjoyable. Traveling can be difficult, so our staff do
                    everything possible to make your stay comfortable and enjoyable. We
                    are excited to see you soon!
                  </p>
                </div>
              </div>
            </div>
            <div className="testimonial-slider">
              <div className="row">

                <div className="col-sm-4">
                  <div className="testimonial-single">
                    <div className="testimonial-quote">
                      <span className="testimonial-quote-icon">
                        <i className="fal fa-quote-right" />
                      </span>
                      <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                      <p>
                      Really touched by thier hospitality and the efforts made by them for making our 25th anniversary quite special. Mr. Dev arranged river rafting & bunjee jumping for us and made sure we don't have to hustle for anything. We booked the premium river view room with private balcony & the view was so beautiful that we spend most of our time relishing it. We tried the authentic garhwali food their for the first time & it was mouth watering, hats off to the Chef. We'll be back soon.
                      </p>
                    </div>
                    <div className="testimonial-content">
                      <div className="testimonial-author-img">
                        <img src="assets/img/testimonial/5.jpg" alt="" />
                      </div>
                      <div className="testimonial-author-info">
                        <h4>Vijender Sajwan</h4>
                        <p>Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="testimonial-single">
                    <div className="testimonial-quote">
                      <span className="testimonial-quote-icon">
                        <i className="fal fa-quote-right" />
                      </span>
                      <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                      <p>
                      Very great atmosphere, welcoming to families, and excellent service. Everyone, especially Dawn, is really friendly and makes you feel welcome. I would strongly advise. Very welcoming management that went above and beyond to ensure that we had a good time. The plants are well-maintained. The room was very tidy and roomy. Great location too.  Everything you could possible need was inside the room. It was clean, comfy, and peaceful. Excellent vacation at this fantastic site.
                      </p>
                    </div>
                    <div className="testimonial-content"><div className="testimonial-author-img">
                        <img src="assets/img/testimonial/6.jpg" alt="" />
                      </div>
                      <div className="testimonial-author-info">
                        <h4>Rohit Minocha</h4>
                        <p>Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="testimonial-single">
                    <div className="testimonial-quote">
                      <span className="testimonial-quote-icon">
                        <i className="fal fa-quote-right" />
                      </span>
                      <div className="testimonial-rate">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                      <p>
                      Everything perfectly managed, as described by the resort’s management at the time of booking, hospitality comfort everything in place with great scenic natural beautiful location adjacent river flowing, we have a group of 45 people who stayed on 10th and 11th Sept 22 , everyone enjoyed the stay with great satisfaction, we definitely come again to enjoy the fullest .Mr Dev resorts manager I can say a great host who is ready to cooperate where ever you require him.
                      </p>
                    </div>
                    <div className="testimonial-content">
                      <div className="testimonial-author-img">
                        <img src="assets/img/testimonial/7.jpg" alt="" />
                      </div>
                      <div className="testimonial-author-info">
                        <h4>Shazid Khan</h4>
                        <p>Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 
              
              <div className="testimonial-single">
                <div className="testimonial-quote">
                  <span className="testimonial-quote-icon">
                    <i className="fal fa-quote-right" />
                  </span>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <p>
                    This a strategic location where the intersection of the river
                    happens. The resort is a peaceful Oasis. Food is awesome and the
                    ambiance is surreal. The river side rooms will make you fall asleep
                    due the running water sounds work as therapy. Dev took care of our
                    personal needs very well &amp; helped us arranging the city tour
                    &amp; river rafting. Recommended to everyone for a great family
                  </p>
                </div>
                <div className="testimonial-content">
                  <div className="testimonial-author-img">
                    <img src="assets/img/testimonial/8.jpg" alt="" />
                  </div>
                  <div className="testimonial-author-info">
                    <h4>SABYASACHEE BISWAL</h4>
                    <p>Customers</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="blog-area py-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="site-heading text-center">
                  <span className="site-title-tagline">Our Blog</span>
                  <p>
                    Never miss a chance to go camping. Spend as much time as possible
                    camping and rafting. Here is where the ideal camping trip begins.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="blog-item">
                  <div className="blog-item-thumb">
                    <img src="assets/img/blog/blog-07.jpg" alt="Thumb" style={{objectFit:'cover'}} />
                  </div>
                  <div className="blog-item-info">
                    <div className="blog-item-meta"></div>
                    <h5>
                      <a href="#" className="blog-item-title">
                        Camping
                      </a>
                    </h5>
                    <p>
                      HIM River Resort Rishikesh has unique mud cottages that are
                      designed in a basic yet comfortable way for a couple or family for
                      stay and camping.
                    </p>
                    <a className="blog-item-more" href={'/camping-in-rishikesh'}>
                      Read More <i className="far fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="blog-item">
                  <div className="blog-item-thumb">
                    <img src="assets/img/blog/blog-08.jpg" alt="Thumb" style={{objectFit:'cover'}}  />
                  </div>
                  <div className="blog-item-info">
                    <div className="blog-item-meta"></div>
                    <h5>
                      <a href="#" className="blog-item-title">
                        Rafting
                      </a>
                    </h5>
                    <p>
                      Rafting is an activity that involves using an inflatable raft to
                      go down a river or other body of water. And you can also enjoy
                      rafting at Him River Resort.
                    </p>
                    <a className="blog-item-more" href={'/rafting-in-rishikesh'}>
                      Read More <i className="far fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="blog-item">
                  <div className="blog-item-thumb">
                    <img src="assets/img/blog/blog-09.jpg" alt="Thumb" style={{objectFit:'cover'}}  />
                  </div>
                  <div className="blog-item-info">
                    <div className="blog-item-meta"></div>
                    <h5>
                      <a href="#" className="blog-item-title">
                        Trekking
                      </a>
                    </h5>
                    <p>
                      If you like adventure. So you should do trekking, it strengthens
                      your body. Trekking gives you a chance to break away from your
                      routine and learn new things.
                    </p>
                    <a className="blog-item-more" href={'/trekking-in-rishikesh'}>
                      Read More <i className="far fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </main>
    </>

  )
}

export default Home